import Vue from 'vue'
import Vuex from 'vuex'
import tradeFinancing from './modules/tradeFinancing'
// import axios from 'axios'
// import {baseUrl} from '../systemConfiguration/index'
Vue.use(Vuex)
// 登录验证
export default new Vuex.Store({
  state: {
    user: false,
    loading: false, // 可用于全局的loading状态值  在请求接口的时候
    dictionaryEntry: null, // 字典所有数据
    customColors: localStorage.getItem('customColors') || '#fff', // 自定义颜色
    multiPageLabelArr: [], // 多页标签
    systemMenuObj: {},
    resourceByTaskObj: null,
    latestUsageMenuList: JSON.parse(localStorage.getItem('latestUsageMenuList')) || [] // 最新使用菜单
  },
  mutations: {
    // 设置多页标签  添加的
    getSystemMenu(state, obj) {
      state.systemMenuObj = { ...obj }
      localStorage.setItem('systemMenuObj', JSON.stringify(obj))
    },
    // 设置多页标签  添加的
    getmultiPageLabel(state, pageLabel) {
      const flag = state.multiPageLabelArr.some(item => {
        if (item.path === pageLabel.path)item.fullPath = pageLabel.fullPath
        return item.path === pageLabel.path
      })
      if (!flag)state.multiPageLabelArr.push(pageLabel)
    },
    // 设置多页标签  赋值的
    setmultiPageLabel(state, arr) {
      state.multiPageLabelArr = [...arr]
    },
    // 设置最近使用菜单
    setLatestUsageMenuList(state, obj) {
      const index = state.latestUsageMenuList.findIndex((item) => item.resName === obj.resName)
      if (index !== -1) return
      state.latestUsageMenuList.unshift({ ...obj })
      if (state.latestUsageMenuList.length > 6) state.latestUsageMenuList.pop()
      localStorage.setItem('latestUsageMenuList', JSON.stringify(state.latestUsageMenuList))
    },
    // 更改主题颜色
    setCustomColors(state, customColors) {
      state.customColors = customColors
      localStorage.setItem('customColors', customColors)
    },
    // 改变loading的状态
    changeLoading(state, type) {
      state.loading = type
    },
    setResourceByTaskObj(state, resourceByTaskObj) {
      state.resourceByTaskObj = resourceByTaskObj
      sessionStorage.setItem('resourceByTaskObj', JSON.stringify(resourceByTaskObj))
    },
    // 登录
    login(state, user) {
      state.user = user
      localStorage.setItem('userInfo', user)
    },
    // 获取所有字典项
    getDictionaryEntry(state, dictionaryEntry) {
      state.dictionaryEntry = dictionaryEntry
      localStorage.setItem('dictionaryEntry', JSON.stringify(dictionaryEntry))
    },
    // 退出
    logout(state, user) {
      state.user = ''
      localStorage.setItem('userInfo', '')
    }
  },

  actions: {
    //  async getDictionaryEntry(context){
    //    let res = await axios(baseUrl+'/dataDict/selectAllDict',{});
    //       context.commit('changeDictionaryEntry',res)
    //   }
  },
  getters: {
    // 获取字典单个项的数据
    getDictionaryItem: state => item => {
      const dictionaryEntry =
        state.dictionaryEntry ||
        JSON.parse(localStorage.getItem('dictionaryEntry'))
      return dictionaryEntry[item] || []
    }
  },
  modules: {
    tradeFinancing
  }
})
