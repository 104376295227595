
const SRCURL = `https://api.369tecgroup.com/scm/v1`
const baseURL = `https://api.369tecgroup.com/plat/approveabc/`
const scfURL = `https://api.369tecgroup.com/scf/approveabc/`
const cmsURL = `https://api.369tecgroup.com/cms/approveabc`
const monitorURL = `https://api.369tecgroup.com/monitor/api/v1`
const IMGPATH = `https://www.369tecgroup.com/images/`
const SCMAPI = `https://api.369tecgroup.com/scm/api/`
const PIAOJUURL = `https://api.369tecgroup.com/pjht/operate/`
const projectUrl = `https://b2b.369tecgroup.com/#/`
// 角色类型
const roleType = [
  { id: '222', name: '业务类' },
  { id: '221', name: '管理类' }
]
// 公共角色平台
const rolePlatform = [
  { id: '00', name: '默认自己所属企业' },
  { id: '0101', name: '核心企业' },
  { id: '0102', name: '融资企业' },
  { id: '02', name: '资金方' }
]
// 权限类型
const qType = [
  { id: 1, name: '栏目' },
  { id: 2, name: '菜单' },
  { id: 3, name: '按钮' }
]
// 机构
const orgCodeArr = [
  { id: '00000', name: '产融链平台' },
  { id: '0000', name: '平台方' },
  { id: '10024', name: '资金方' },
  { id: '01', name: '核心企业' },
  { id: '02', name: '融资企业' },
  { id: '03', name: '核心企业&融资企业' }
]
// 新闻类型
const newsType = [
  { id: 1, name: '公司新闻' },
  { id: 2, name: '行业动态' }
]
// 操作类型
const operationType = [
  { id: 0, name: '登录' },
  { id: 1, name: '新增' },
  { id: 2, name: '修改' },
  { id: 3, name: '删除' }
]
// 业务类型
const businessType = [
  { id: 1, name: '登录模块' },
  { id: 2, name: '产品模块' },
  { id: 3, name: '额度模块' },
  { id: 4, name: '融资模块' }
]
// 企业角色
const enterpriseRole = [
  { id: '01', name: '核心企业' },
  { id: '02', name: '供应商' },
  { id: '03', name: '经销商' },
  { id: '04', name: '代采商' }
]
// 账户状态
const accountStatus = [
  { id: '01', name: '待补录' },
  { id: '02', name: '待平台审核' },
  { id: '-1', name: '已驳回' },
  { id: '99', name: '审核通过' },
  { id: '04', name: '平台终审' }
]
// 注册来源
const registeredSource = [
  { id: '00', name: '平台代理' },
  { id: '01', name: '门户商城' }
]
// 录入方
const intoParty = [
  { id: '00', name: '企业方' },
  { id: '01', name: '平台方' }
]
export {
  SCMAPI,
  SRCURL,
  baseURL,
  scfURL,
  cmsURL,
  monitorURL,
  IMGPATH,
  PIAOJUURL,
  projectUrl,
  intoParty,
  registeredSource,
  accountStatus,
  enterpriseRole,
  businessType,
  operationType,
  newsType,
  roleType,
  orgCodeArr,
  rolePlatform,
  qType
}
