const supplyChainRoute = [
  {
    path: '/supplyChain/collectionProject',
    name: 'collectionProject',
    component: () => import('../views/supplyChain/collectionProject.vue'),
    meta: {
      name: '集采项目',
      keepAlive: true,
      parentPath: '/supplyChain/collectionProject',
      requireAuth: true
    }
  },
  // {
  //   path: '/supplyChain/collectionProject',
  //   name: 'collectionProject',
  //   component: () => import('../views/supplyChain/collectionProject.vue'),
  //   meta: {
  //     name: '集采项目',
  //     parentPath: '/supplyChain/collectionProject',
  //     requireAuth: true
  //   }
  // },
  {
    path: '/collectionProject/detail',
    name: 'collectionProjectDetail',
    component: () => import('../views/supplyChain/children/collectionProjectDetail.vue'),
    meta: {
      name: '集采项目详情',
      parentPath: '/supplyChain/collectionProject',
      requireAuth: true
    }
  },
  // {
  //   path: '/collectionProject/customerDetail',
  //   name: 'customerDetail',
  //   component: () => import('../views/supplyChain/children/customerDetail.vue'),
  //   meta: {
  //     name: '集采认购详情',
  //     parentPath: '/supplyChain/collectionProject',
  //     requireAuth: true
  //   }
  // },
  {
    path: '/supplyChain/collectionSubscribe',
    name: 'collectionSubscribe',
    component: () => import('../views/supplyChain/collectionSubscribe.vue'),
    meta: {
      name: '集采认购',
      keepAlive: true,
      parentPath: '/supplyChain/collectionSubscribe',
      requireAuth: true
    }
  },
  {
    path: '/collectionSubscribe/collectionSubscribeDetail',
    name: 'collectionSubscribeDetail',
    component: () => import('../views/supplyChain/children/subscribeDetail.vue'),
    meta: {
      name: '集采认购明细',
      parentPath: '/supplyChain/collectionSubscribe',
      requireAuth: true
    }
  },
  {
    path: '/supplyChain/aggregatePurchaseOrder',
    name: 'aggregatePurchaseOrder',
    component: () => import('../views/shoppingMall/orderManage.vue'),
    meta: {
      name: '集采订单',
      keepAlive: true,
      parentPath: '/supplyChain/aggregatePurchaseOrder',
      requireAuth: true
    }
  }
]
export default supplyChainRoute
