import { getFinancialSideList, selectProductName } from '@/api/tradeFinancing.js'
import { selectFundcmp, getCmpListByCmpRoleList, selectBankProList } from '@/api/memberManagement.js'
import { getGaranteeCompanyList } from '@/api/guaranteeCompany.js'
const state = {
  coreEnterPriseList: [], // 核心企业列表 cmpName cmpUnicode  cmpCode
  cmpNameList: [], // 产品新增查询已激活的资金方名称
  bankList: [], // 资金方 funderName funderUnicode id
  fundProdNoList: [], // 资金方编号
  guarCmpList: [], // 担保方
  productNameList: [] //  通过资金方id和核心企业id查询产品名 跟核心企业有关的产品列表
}

const mutations = {
  setBankList(state, data) {
    state.bankList = data
  },
  // 获取跟产品相关的核心企业
  setCmpListByCmpRoleList(state, data) {
    state.coreEnterPriseList = data
  },
  // 产品新增查询已激活的资金方名称
  setCmpNameList(state, data) {
    state.cmpNameList = data
  },
  setFundProdNoList(state, data) {
    state.fundProdNoList = data
  },
  setGuarCmpList(state, data) {
    state.guarCmpList = data
  },
  setProductNameList(state, data) {
    state.productNameList = data
  }

}

const actions = {

  queryCmpListByCmpRoleList({ commit }, params) {
    return new Promise((resolve) => {
      getCmpListByCmpRoleList(params = { cmpRole: '11', ...params }, res => {
        commit('setCmpListByCmpRoleList', res.data)
        resolve()
      })
    })
  },
  queryCmpNameList({ commit }, params) {
    getFinancialSideList(params, res => {
      commit('setCmpNameList', res.data)
    })
  },
  querBankList({ commit }, params) {
    return new Promise((resolve) => {
      selectFundcmp(res => {
        commit('setBankList', res.data)
        resolve()
      })
    })
  },
  querFundProdNoList({ commit }, params) {
    selectBankProList(params, res => {
      commit('setFundProdNoList', res.data)
    })
  },
  querGuarCmpList({ commit }, params) {
    return new Promise((resolve) => {
      getGaranteeCompanyList(res => {
        commit('setGuarCmpList', res.data)
        resolve()
      })
    })
  },
  queryProductNameList({ commit }, params) {
    selectProductName(params, res => {
      commit('setProductNameList', res.data)
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
