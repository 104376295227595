import store from '../vuex/store'
import moment from 'moment'
/**
 * 时间戳
 * @param {*} timestamp  时间戳
 */
const timestampToTime = (timestamp, type) => {
  const date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + '-'
  const M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':'
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return type ? Y + M + D : Y + M + D + h + m + s
}
/**
 * 存储localStorage
 */
const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}
function roundUpToInteger(money, num = 2) {
  if (!money || !Number(money)) return 0.00
  let digit = 1
  for (let index = 0; index < num; index++) {
    digit += '0'
  }
  return (Math.round((money + Number.EPSILON) * Number(digit)) / Number(digit)).toFixed(num)
}
/**
 * 获取localStorage
 */
const getStore = name => {
  if (!name) return
  return window.localStorage.getItem(name)
}

/**
 * 删除localStorage
 */
const removeStore = name => {
  if (!name) return
  window.localStorage.removeItem(name)
}

/**
 * 设置cookie
 **/
function setCookie(name, value, day) {
  const date = new Date()
  date.setDate(date.getDate() + day)
  document.cookie = name + '=' + value + ';expires=' + date
}

/**
 * 获取cookie
 **/
function getCookie(name) {
  const reg = RegExp(name + '=([^;]+)')
  const arr = document.cookie.match(reg)
  if (arr) {
    return arr[1]
  } else {
    return ''
  }
}
// 数据转为树结构的方法
// pid是子元素的属性名  ID是父元素上的属性名
function translateDataToTree(data, pid, id) {
  // 没有父节点的数据
  let parents = []; let children = []
  parents = data.filter(
    value => value[pid] === 'undefined' || value[pid] == null || value[pid] === ''
  )

  // 有父节点的数据
  children = data.filter(
    value => value[pid] !== 'undefined' && value[pid] != null
  )
  // 定义转换方法的具体实现
  const translator = (parents, children) => {
    // 遍历父节点数据
    parents.forEach(parent => {
      // 遍历子节点数据
      children.forEach((current, index) => {
        // 此时找到父节点对应的一个子节点
        if (current[pid] === parent[id]) {
          // 对子节点数据进行深复制，这里只支持部分类型的数据深复制，对深复制不了解的童靴可以先去了解下深复制
          const temp = JSON.parse(JSON.stringify(children))
          // 让当前子节点从temp中移除，temp作为新的子节点数据，这里是为了让递归时，子节点的遍历次数更少，如果父子关系的层级越多，越有利
          temp.splice(index, 1)
          // 让当前子节点作为唯一的父节点，去递归查找其对应的子节点
          translator([current], temp)
          // 把找到子节点放入父节点的children属性中
          typeof parent.children !== 'undefined'
            ? parent.children.push(current)
            : (parent.children = [current])
        }
      })
    })
  }

  // 调用转换方法
  translator(parents, children)
  // 返回最终的结果
  return parents
}
// 数组对象去重
function arrayObjectsDeduplicated(arr, key) {
  if (!(arr instanceof Array)) return []
  var obj = {}
  const arr2 = arr.reduce(function(item, next) {
    obj[next[key]] ? '' : (obj[next[key]] = true && item.push(next))
    return item
  }, [])
  return arr2
}
/**
 * 删除cookie
 **/
function delCookie(name) {
  setCookie(name, null, -1)
}

// 金额 千位逗号分隔符过滤器
function numberToCurrency(value, Symbol) {
  if (!value || 0) return '0.00'
  // 获取整数部分
  const intPart = Math.trunc(value)
  // 整数部分处理 添加（,）
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // 预定义小数部分
  let floatPart = ''
  // 将数值截取为小数部分和整数部分
  const valueArray = value.toString().split('.')
  // 有小数 获取小数部分
  if (valueArray.length === 2) {
    floatPart = valueArray[1].length < 2 ? valueArray[1].toString() + '0' : valueArray[1].toString()
    return (Symbol ? '￥' : '') + intPartFormat + '.' + floatPart
  }
  return (Symbol ? '￥' : '') + intPartFormat + '.' + '00'
}
function downloadFile(url, name = '代理采购合同.pdf') {
  if (!url) return
  fetch(url, {
    method: 'get',
    mode: 'cors'
  })
    .then((response) => response.blob())
    .then((res) => {
      const downloadUrl = window.URL.createObjectURL(
        // new Blob() 对后端返回文件流类型处理
        new Blob([res], {
          type: 'application/pdf'
        })
      )
      // word文档为msword,pdf文档为pdf
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}
// 下载文件
function downloadFile2(url, name, typeConversion = 'pdf') {
  if (!url) return
  fetch(url, {
    method: 'get',
    mode: 'cors'
  })
    .then((response) => response.blob())
    .then((res) => {
      // 进行type处理
      let type = ''
      switch (typeConversion) {
        case 'png':
          type = 'image/png'
          break
        case 'jpeg':
          type = 'image/jpeg'
          break
        case 'jpg':
          type = 'image/jpeg'
          break
        case 'pdf':
          type = 'application/pdf'
          break
        case 'docx':
          type =
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          break
        case 'doc':
          type = 'application/msword'
          break
        case 'xls':
          type = 'application/vnd.ms-excel'
          break
        case 'xlsx':
          type =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          break
        case 'zip':
          type = 'application/zip'
          break
      }
      const downloadUrl = window.URL.createObjectURL(
        // new Blob() 对后端返回文件流类型处理
        new Blob([res], {
          type
        })
      )
      // word文档为msword,pdf文档为pdf
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
}

// 加法
function floatAdd(arg1, arg2) {
  var r1, r2, m
  try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2))
  return (arg1 * m + arg2 * m) / m
}
// 减法精度处理
function subPrecision(arg1, arg2) {
  let r1, r2
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = Math.pow(10, Math.max(r1, r2))
  // 动态控制精度长度
  // const n = (r1 >= r2) ? r1 : r2
  return ((arg1 * m - arg2 * m) / m)
}
// 乘法
function floatMul(arg1, arg2) {
  var m = 0; var s1 = arg1.toString(); var s2 = arg2.toString()
  try { m += s1.split('.')[1].length } catch (e) { () => {} }
  try { m += s2.split('.')[1].length } catch (e) { () => {} }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}
// 金额自动补齐N位小数以及加上千分位
function thousandsFractionsSmaller(money, num = 2) {
  if (!money || !Number(money)) return '—'
  let digit = 1
  for (let index = 0; index < num; index++) {
    digit += '0'
  }
  money = (Math.round((money + Number.EPSILON) * Number(digit)) / Number(digit)).toFixed(num)
  var numArry = money.split('.')
  var l = numArry[0].split('').reverse()
  var t = ''
  for (var i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '')
  }
  return t.split('').reverse().join('') + '.' + numArry[1]
}
// 获取银行的名字
function getBankName(id) {
  if (!id) return ''
  const bankArr = store.getters.getDictionaryItem('settleBankName') || []
  const obj = bankArr.find((item) => id === item.dictId) || {}
  return obj.dictName || ''
}
// 获取字典项的名称
function getDictionaryName(dictionaryName, id) {
  if (!id) return ''
  const bankArr = store.getters.getDictionaryItem(dictionaryName) || []
  const obj = bankArr.find((item) => id === item.dictId) || {}
  return obj.dictName || ''
}
const date = function(value) {
  if (!value) return '----'
  return moment(value).format('YYYY-MM-DD')
}
// const month = function(value) {
//   if (!value) return '----'
//   return moment(value).format('YYYY-MM')
// }
// const time = function(value) {
//   if (!value) return '----'
//   return moment(value).format('YYYY-MM-DD HH:mm:ss')
// }
// const minute = function(value) {
//   if (!value) return '----'
//   return moment(value).format('YYYY-MM-DD HH:mm')
// }
const formatMoney = function(n) {
  if (n === '***') {
    return '***'
  } else {
    const N = !isNaN(Number(n)) ? Number(n) : 0
    return N.toLocaleString('zh', {
      style: 'decimal',
      currency: 'cny',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2, // 最少保留小数的位数
      maximumFractionDigits: 2, // 最多保留小数的位数
      useGrouping: true
    })
  }
}
function moneyUnit(n) {
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) return ''
  n = n.toString()
  // 12 3百 4千 5万 6十万 7百万 8千万 9亿 10十亿 11百亿 12千亿 13万亿 14十万亿
  const unit = ['', '', '百', '千', '万', '十万', '百万', '千万', '亿', '十亿', '百亿', '千亿', '万亿', '十万亿', '十万亿', '十万亿', '十万亿', '十万亿', '十万亿', '十万亿']
  const p = n.indexOf('.'); let str = n
  if (p > 0) {
    str = n.substring(0, p)
  }
  return unit[str.length - 1]
}
// 数字变大写
function numberDX2(n) {
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) return ''
  var unit = '京亿万仟佰拾兆万仟佰拾亿仟佰拾万仟佰拾元角分'; var str = ''
  n += '00'
  var p = n.indexOf('.')
  if (p >= 0) { n = n.substring(0, p) + n.substr(p + 1, 2) }
  unit = unit.substr(unit.length - n.length)
  for (var i = 0; i < n.length; i++) str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i)
  return str.replace(/零(仟|佰|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(兆|万|亿|元)/g, '$1').replace(/(兆|亿)万/g, '$1').replace(/(京|兆)亿/g, '$1').replace(/(京)兆/g, '$1').replace(/(京|兆|亿|仟|佰|拾)(万?)(.)仟/g, '$1$2零$3仟').replace(/^元零?|零分/g, '').replace(/(元|角)$/g, '$1整')
}
// 传入的时间与当前时间相差多久
const distanceDays = (timestamp) => {
  if (!timestamp) return 0
  const oldDate =
    timestamp instanceof Number ? timestamp : new Date(timestamp).valueOf()
  const newDate = new Date().valueOf()
  const seconds = parseInt(newDate) - parseInt(oldDate)
  return Math.ceil(seconds / 60 / 24 / 60 / 1000)
}
function getDate() {
  var now = new Date()
  var year = now.getFullYear() // 得到年份
  var month = now.getMonth() + 1 // 得到月份
  var date = now.getDate() // 得到日期
  month = month.toString().padStart(2, '0')
  date = date.toString().padStart(2, '0')
  var defaultDate = `${year}-${month}-${date}`
  return defaultDate
}
// 数据部份展示掩码
function plusXing(str, frontLen, endLen) {
  var len = str.length - frontLen - endLen
  var xing = ''
  for (var i = 0; i < len; i++) {
    xing += '*'
  }
  return str.substring(0, frontLen) + xing + str.substring(str.length - endLen)
}
// 获取字典
function getDictName(dictArr = [], dictId = '') {
  if (!dictId || !dictId) return
  const strDictId = String(dictId)
  const dictName = dictArr.find((v) => v.dictId === strDictId).dictName
  return dictName
}
/**
 * 导出
 **/
export {
  getDate,
  getDictionaryName,
  distanceDays,
  thousandsFractionsSmaller,
  downloadFile,
  downloadFile2,
  translateDataToTree,
  timestampToTime,
  arrayObjectsDeduplicated,
  setStore,
  getStore,
  removeStore,
  setCookie,
  getCookie,
  delCookie,
  numberToCurrency,
  roundUpToInteger,
  floatAdd,
  getBankName,
  subPrecision,
  formatMoney,
  date,
  moneyUnit,
  numberDX2,
  plusXing,
  getDictName,
  floatMul
}
