/**
* 头部菜单
*/
<template>
  <div class="header-demo">
    <!-- 上面菜单 -->
    <!-- <p class="welcome">
      您好，欢迎进入运营平台系统！
    </p> -->
    <div class="left-box">
      <img class="logo" src="@/assets/icon/big_logo.png" alt="">
      <i class="line" />
      <img src="@/assets/icon/menu.png" alt="icon" class="expand" @click="changeCollapsed">
      <p>{{ systemMenuObj.resName }}</p>
    </div>
    <el-dropdown>
      <span class="setting">
        <span class="org-name">欢迎您，{{ user.orgName }}!</span>
        <i class="line" />
        <img src="../assets/img/head_portrait.png" alt="">
        <span v-if="user" class="user-name">
          {{ user.userName || user.orgName }}
          <i class="el-icon-caret-bottom el-icon--right" />
        </span>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <p @click="dialogFormVisible = true">
            修改密码
          </p>
        </el-dropdown-item>
        <el-dropdown-item>
          <p @click="subjectDefinitionVisible = true">
            主题定义
          </p>
        </el-dropdown-item>
        <el-dropdown-item>
          <p @click="exit">
            退出登录
          </p>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- <div v-show="$store.state.multiPageLabelArr.length > 0" class="multiPageLabel">
      <el-tabs v-model="activePath">
        <el-tab-pane
          v-for="(item, index) in $store.state.multiPageLabelArr"
          :key="index + Date.now()"
          :label="item.name"
          :name="item.path"
        >
          <span slot="label">
            <el-tag
              closable
              size="medium"
              :type="activePath === item.path ? 'danger' : 'info'"
              @close="handleClose(item.path)"
            >
              <router-link class="link" :to="{ path: item.fullPath }">{{ item.name }}</router-link>
            </el-tag>
          </span>
        </el-tab-pane>
      </el-tabs>
      <span class="refreshButton el-icon-refresh" @click="$router.go(0)" />
      <el-dropdown trigger="click">
        <span class="tab-close">
          <img src="../assets/img/tab_close.svg" alt="">
        </span>
        <el-dropdown-menu slot="dropdown" class="tab-close-item">
          <el-dropdown-item>
            <p @click="closeOther">
              <i class="tab-close-icon close-other" />
              关闭其他
            </p>
          </el-dropdown-item>
          <el-dropdown-item>
            <p @click="closeAll">
              <i class="tab-close-icon close-all" />
              关闭所有
            </p>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div> -->
    <!-- <el-menu
      class="el-menu-demo"
      mode="horizontal"
      :background-color="$store.state.customColors"
      text-color="#fff"
      active-text-color="#fff"
    >
      <p class="welcome">
        您好，欢迎进入xxxxx系统！
      </p>
      <el-submenu index="2" class="submenu">
        <template slot="title">{{ user.userName }}</template>
        <el-menu-item @click="subjectDefinitionVisible = true">主题定义</el-menu-item>
        <el-menu-item index="2-1" @click="dialogFormVisible = true">修改密码</el-menu-item>
        <el-menu-item @click="exit()" index="2-3">退出</el-menu-item>
      </el-submenu>
    </el-menu> -->
    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="旧密码" prop="oldPassword1">
          <el-input v-model.number="ruleForm.oldPassword1" autocomplete="off" />
        </el-form-item>
        <el-form-item label="新密码" prop="password1">
          <el-input v-model="ruleForm.password1" type="password" autocomplete="off" />
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPassword">
          <el-input v-model="ruleForm.checkPassword" type="password" autocomplete="off" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">
            提交
          </el-button>
          <el-button @click="dialogFormVisible = false">
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 主题定义 -->
    <el-drawer
      title="主题选择"
      class="subjectDefinitionVisible"
      :visible.sync="subjectDefinitionVisible"
      direction="rtl"
      :modal="false"
    >
      <div class="subjectsChoiceBox">
        <div class="colorBox">
          <span v-for="item in colorArr" :key="item" :style="'background:' + item" @click="changeCustColor(item)" />
        </div>
        <div class="customBox">
          <p>自定义颜色:</p>
          <el-color-picker v-model="activeColor" @active-change="changeCustColor" />
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { loginOut, updatePassword } from '../api/login'
import myLogin from '../views/login'
import md5 from 'js-md5'
export default {
  name: 'Navcon',
  mixins: [myLogin],
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('旧密码不能为空'))
      }
      callback()
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPassword !== '') {
          this.$refs.ruleForm.validateField('checkPassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      activePath: '/goods/Goods',
      ruleForm: {},
      activeColor: '',
      colorArr: ['rgba(11,117,207,1)', 'rgba(252,65,115,1)', 'rgba(225,122,20,1)', 'rgba(66,160,33,1)', 'rgba(33,231,218,1)', 'rgba(55,107,251,1)', 'rgba(65,124,167,1)', 'rgba(68,81,96,1)', 'rgba(167,0,253,1)'],
      subjectDefinitionVisible: false,
      rules: {
        password1: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPassword: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        oldPassword1: [
          { validator: checkAge, trigger: 'blur' }
        ]
      },
      collapsed: true,
      dialogFormVisible: false,
      imgshow: require('../assets/img/show.png'),
      imgsq: require('../assets/img/sq.png'),
      user: {},
      systemMenuObj: {},
      isCollapsing: false
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        this.activePath = newVal.path
      },
      immediate: true,
      deep: true
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    this.user = JSON.parse(localStorage.getItem('userdata'))
    this.systemMenuObj = this.$store.systemMenuObj || { ...JSON.parse(localStorage.getItem('systemMenuObj')) }
  },
  methods: {
    // 改变自定义颜色
    changeCustColor(color) {
      this.activeColor = color
      this.$store.commit('setCustomColors', color)
    },
    // 修改密码确认
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const passwordObj = {
            oldPassword: myLogin.methods.encrypt(md5(this.ruleForm.oldPassword1 + '')),
            password: myLogin.methods.encrypt(md5(this.ruleForm.password1))
          }
          updatePassword(passwordObj, () => {
            this.dialogFormVisible = false
            this.$message.success('修改成功！')
          })
        }
      })
    },
    // 退出登录
    exit() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          setTimeout(() => {
            this.$store.commit('logout', 'false')
            this.$router.push({ path: '/login' })
            this.$message({
              type: 'success',
              message: '已退出登录!'
            })
          }, 1000)
          loginOut({}, res => {
            res.code === 200 ? this.$message.success(res.msg) : this.$message.error(res.msg)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 切换显示
    toggle(showtype) {
      this.collapsed = !showtype
      this.$root.Bus.$emit('toggle', this.collapsed)
    },
    // 关闭所有
    closeAll() {
      this.$router.push({ path: '/homePage/index' })
      this.$store.commit('setmultiPageLabel', [])
    },
    // 关闭其他
    closeOther() {
      const arr = this.$store.state.multiPageLabelArr
      arr.forEach(item => {
        if (this.activePath === item.path) {
          this.$store.commit('setmultiPageLabel', [{ ...item }])
        }
      })
    },
    // 移除点击的
    handleClose(path) {
      const arr = this.$store.state.multiPageLabelArr
      if (arr.length === 1) {
        this.closeAll()
        return
      }
      let num = 0
      let flag = false
      // 删除当前点击的这一条
      arr.map((item, index) => {
        if (item.path === path) {
          arr.splice(index, 1)
        }
        // 判断删除的这个是不是当前active的
        if (this.activePath === item.path && this.activePath === path) {
          num = index
          flag = true
        }
      })
      if (flag) this.$router.push({ path: arr[num - 1] && arr[num - 1].path || arr[num + 1] && arr[num + 1].path || '/enterpriseInformation/addInformation' })
      this.$store.commit('setmultiPageLabel', arr)
    },
    changeCollapsed() {
      this.isCollapsing = !this.isCollapsing
      this.$emit('changeWidth', { isCollapsing: this.isCollapsing })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
}
.submenu {
  float: right;
}
.buttonimg {
  height: 60px;
  background-color: transparent;
  border: none;
}
.showimg {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 17px;
  left: 17px;
}
.showimg:active {
  border: none;
}
.header-demo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $assistColor9;
  color: #fff;
   padding: 0 22px;

  .left-box {
    display: flex;
    align-items: center;
    font-size: 18px;
    .logo {
      width: 160px;
      height: 40px;
    }
    .line {
      display: inline-block;
      height: 22px;
      width: 1px;
      background-color: rgba(255,255,255,0.6);
      margin-left: 30px;
    }
    .expand {
      width: 26px;
      height: 26px;
      margin-left: 22px;
      margin-right: 16px;
      cursor: pointer;
    }
  }

  .setting {
    color: #fff;
    .org-name {
      color: #DAE5E9;
    }
    .line {
      display: inline-block;
      height: 22px;
      width: 1px;
      background-color: rgba(255,255,255,0.6);
      margin: 0 22px;
    }
    img {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }
    .user-name {
      cursor: pointer;
      i {
        color: $tableTbodyfontColor;
      }
    }
  }
}
</style>
