<template>
  <div class="login-wrap">
    <h1 class="logoImg">
      <img
        src="@/assets/img/logo.png"
        alt="logo"
      >
    </h1>
    <div class="textDescription">
      <h2>运营端后台系统</h2>
      <p>简洁、高效的企业协同合作与管理平台</p>
    </div>
    <div class="contentBox">
      <div class="right">
        <div class="rightBox">
          <h3 class="rightTitle">
            运营端管理系统登录
          </h3>
          <el-form
            ref="ruleForm"
            label-position="left"
            :model="ruleForm"
            :rules="rules"
            label-width="0px"
            class="login-container"
          >
            <el-form-item prop="userName">
              <el-input
                v-model="ruleForm.userName"
                type="text"
                auto-complete="off"
                placeholder="账号"
              />
            </el-form-item>
            <el-form-item prop="password1">
              <el-input
                v-model="ruleForm.password1"
                type="password"
                auto-complete="off"
                placeholder="密码"
              />
            </el-form-item>
            <!-- <el-form-item prop="kaptcha">
              <div class="flexBox">
                <el-input
                  v-model="ruleForm.kaptcha"
                  type="text"
                  auto-complete="off"
                  placeholder="图形验证码"
                  @keyup.enter.native="submitForm('ruleForm')"
                />
                <img
                  :src="ruleForm.codeimg"
                  alt
                  class="codeimg"
                  @click="getcode()"
                >
              </div>
            </el-form-item> -->
            <slide-verify
              :refresh-slide="refreshSlide"
              @moveCheck="moveCheck"
            />
            <el-form-item>
              <el-button
                class="elButton"
                type="primary"
                :loading="logining"
                :class="Object.keys(ruleForm.imageCaptchaTrackReq).length == 0 ? 'prohibit': 'operate'"
                @click="submitForm('ruleForm')"
              >
                登录
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import { login, getCodes } from '../api/login'
import { setCookie, getCookie, delCookie } from '../utils/util'
import SlideVerify from '@/components/SlideVerify.vue'
// 双层加密   先MD5  再jsencrypt
import md5 from 'js-md5'
import JSEncrypt from 'jsencrypt'
export default {
  name: 'Login',
  components: { SlideVerify },
  data() {
    return {
      // 定义loading默认为false
      logining: false,
      // 记住密码
      rememberpwd: false,
      ruleForm: {
        // userName和password默认为空
        userName: '',
        password1: '',
        kaptcha: '',
        codeimg: '',
        imageCaptchaTrackReq: {}
      },
      // rules前端验证
      rules: {
        userName: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password1: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        kaptcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      refreshSlide: null
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    // 获取图形验证码
    // this.getcode()
    // 获取存在本地的用户名密码
    this.getuserpwd()
  },
  // 里面的函数只有调用才会执行
  methods: {
    // pkcs8加密
    encrypt(plaintext) {
      const key = 'MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBANLpJwrVTmdburKp9khRHBUESLp4KVE+S3umgO7ZaOGtnP0CLvvH8JkpMFJSivTGcpTOAzumevweLhOaeRh3k/69xsSKk2hc/TYGuESWltPta8BQ5xFZxne+e0EOkqWYfrSVEyUaWlgik6EbP2dj5w2fZkZI5QAmAa4vGL/VL89rAgMBAAECgYEAjfofqx4+YjuQqXbacIIRSmCQ6N28ypEJ1RoXzzGRdeIePP88S4ejgicE/VXNbsssFNm9uWjvf0p9r/qbrAnLGEYSoYXm9qOzA6rmU9p1eLW2HaRYCM2jAy9E3wkKCBGzklJm8su9GqXHPRmKCHYMaxSwyvRXJlbsWTsqjRfUtAkCQQD8eWy6DDMtyQowDYGhM+E5f91FHCFuFCVMPxMNahWznJBXTHqi+7GLDx1I6E40WRTJAiCK+VeMwzA/KHfiQ1j9AkEA1dskM6jX2V2eQHxtkoF4NKaTp+xmmzUp3a8uw+SBvKYv+YFN7/sCa8zksyrFarDtUv0WOXvV6uzqfIF24poKhwJAXg5iWgE3g8QUnog5lbN9IW2oIhnzvk2VA42v7FxsD0ixYURln5hCsTqFP2cD026oVqYLN5riaddBfxX1a36JhQJBALuUzctWZYavHFl6Cu6AC/06w7d+n32ygzeFKuL+82GS7+/NoJxIMxZ1kYEqmiF8cZ7iifUAD2JST1fIg4w+C88CQQDTNue1HPVHfpUL0kgz5ivFvl5HRZO/1x0/SNGccGRNWwpt3ryXq72BcgvcCZdwZ0eBLdq4+F61MCYEmg+t7RRY'
      const thisKeyPair = new JSEncrypt({ default_key_size: 1024 })
      if (plaintext instanceof String) {
        // 1、JSON.stringify
        plaintext = JSON.stringify(plaintext)
      }
      thisKeyPair.setPublicKey(key)
      return thisKeyPair.encrypt(plaintext)
    },
    // 获取用户名密码
    getuserpwd() {
      if (getCookie('user') !== '' && getCookie('pwd') !== '') {
        this.ruleForm.userName = getCookie('user')
        this.ruleForm.password1 = getCookie('pwd')
        this.rememberpwd = true
      }
    },
    // 获取验证码
    getcode() {
      getCodes(res => {
        this.ruleForm.codeimg = res.captchaBase64
        this.ruleForm.key = res.key
      })
    },
    // 获取info列表
    submitForm(formName) {
      if (Object.keys(this.ruleForm.imageCaptchaTrackReq).length === 0) return
      localStorage.setItem('logintoken', null)
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.logining = true
          this.ruleForm.password = this.encrypt(md5(this.ruleForm.password1))
          const param = JSON.parse(JSON.stringify(this.ruleForm))
          delete param.password1
          login(param, res => {
            if (res.code === 200) {
              if (this.rememberpwd) {
                // 保存帐号到cookie，有效期7天
                setCookie('user', this.ruleForm.userName, 7)
                // 保存密码到cookie，有效期7天
                setCookie('pwd', this.ruleForm.password1, 7)
              } else {
                delCookie('user')
                delCookie('pwd')
              }
              this.logining = false
              // 缓存token
              localStorage.setItem('logintoken', res.data.token)
              // 缓存用户个人信息
              localStorage.setItem('userdata', JSON.stringify(res.data))
              this.$store.commit('login', 'true')
              // 如果请求成功就让他2秒跳转路由
              this.$router.push({ path: '/homePage/index' })
            } else {
              this.$message.error(res.msg)
              this.logining = false
              // this.getcode()
              return false
            }
          }, () => {
            this.logining = false
            // this.getcode()
            this.refreshSlide = String(new Date())
            this.ruleForm.imageCaptchaTrackReq = {}
          })
        } else {
          // 获取图形验证码
          this.getcode()
          this.$message.error('请输入用户名密码！')
          this.logining = false
          return false
        }
      })
    },
    // 获取校验数据
    moveCheck(data) {
      this.ruleForm.imageCaptchaTrackReq = data
    }
  }
}
</script>

<style lang="scss">
.login-wrap {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-image: url(../assets/image/loginImg.png);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 100% 100%;
  .textDescription {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-77%);
    color: #fff;
    p {
      font-size: 32px;
    }
    h2 {
      font-size: 52px;
      padding-bottom: 20px;
      font-weight: 500;
    }
  }
  .logoImg {
    position: absolute;
    left: 68px;
    top: 70px;
    width: 240px;
    img {
      width: 100%;
    }
  }
  .contentBox {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(0, -50%);
    display: flex;
    width: 500px;
    height: 498px;
    background-color: #fff;
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      .rightBox {
        width: 388px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .login-container {
          width: 100%;
          margin-top: 24px;
          .el-form-item {
            margin-bottom: 30px;
            .flexBox {
              display: flex;
            }
            .el-button {
              border-radius: 25px;
              background-color: #c8000a;
              height: 50px;
              width: 100%;
              border-color: #c8000a;

              &.prohibit {
                cursor: default;
                background-color: rgba(0, 0, 0, 0.2);
                border-color: rgba(0, 0, 0, 0.2);
              }
            }
            .el-input {
              height: 50px;
              .el-input__inner {
                height: 100%;
                border-radius: 25px;
              }
            }
            .el-input:hover {
              input {
                border: 1px solid #ccc;
              }
            }
          }
        }
        .rightTitle {
          color: #333;
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
    .left {
      height: 100%;
      width: 37.82%;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .codeimg {
    height: 50px;
    width: 120px;
    margin-left: 12px;
  }
}
</style>
