const tradeRoute = [
  {
    path: '/trade/transactionRules',
    name: 'transactionRules',
    component: () => import('../views/trade/transactionRules.vue'),
    meta: {
      name: '交易规则',
      parentPath: '/trade/transactionRules',
      keepAlive: true,
      requireAuth: true
    }
  }
]
export default tradeRoute
