<template>
  <div class="pageEntryBox">
    <el-container class="index-con" style="height: 100%; ">
      <el-header class="index-header">
        <navcon @changeWidth="changeAsideWidth" />
      </el-header>
      <!-- :style="{left: !isCollapsing ? '272px' : '85px'}" -->
      <el-container class="container">
        <el-aside :class="showclass" class="aside" :width="!isCollapsing ? '192px' : '56px'" :style="'background-color:' + $store.state.customColors">
          <leftnav :is-show="isCollapsing" />
        </el-aside>
        <el-main class="index-main">
          <div v-show="$store.state.multiPageLabelArr.length > 0" class="multiPageLabel">
            <el-tabs v-model="activePath" closable @tab-remove="handleClose">
              <el-tab-pane
                v-for="(item, index) in $store.state.multiPageLabelArr"
                :key="index + Date.now()"
                :label="item.name"
                :name="item.path"
              >
                <span slot="label">
                  <!-- <el-tag
                    closable
                    size="medium"
                    :type="activePath === item.path ? 'danger' : 'info'"
                    @close="handleClose(item.path)"
                  > -->
                  <router-link class="link" :to="{ path: item.fullPath }">{{ item.name }}</router-link>
                  <!-- </el-tag> -->
                </span>
              </el-tab-pane>
            </el-tabs>
            <span class="refresh-button el-icon-refresh" @click="$router.go(0)" />
            <el-dropdown trigger="click">
              <span class="tab-close">
                <img src="@/assets/img/tab_close.svg" alt="">
              </span>
              <el-dropdown-menu slot="dropdown" class="tab-close-item">
                <el-dropdown-item>
                  <p @click="closeOther">
                    <i class="tab-close-icon close-other" />
                    关闭其他
                  </p>
                </el-dropdown-item>
                <el-dropdown-item>
                  <p @click="closeAll">
                    <i class="tab-close-icon close-all" />
                    关闭所有
                  </p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" :class="['page-container-view', $store.state.multiPageLabelArr.length > 0 ? 'intervalRouterLink' : '']" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" :class="['page-container-view', $store.state.multiPageLabelArr.length > 0 ? 'intervalRouterLink' : '']" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
// 导入组件
import navcon from '../components/navcon.vue'
import leftnav from '../components/leftnav.vue'
export default {
  name: 'Index',
  // 注册组件
  components: {
    navcon,
    leftnav
  },
  data() {
    return {
      showclass: 'asideshow',
      activeFullPath: '',
      isCollapsing: false,
      activePath: '/goods/Goods'
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        this.activeFullPath = newVal.fullPath
        this.activePath = newVal.path
        this.$store.commit('getmultiPageLabel', { path: newVal.path, fullPath: newVal.fullPath, name: newVal.meta.name || newVal.meta.title })
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    // 监听
    // this.$root.Bus.$on('toggle', value => {
    //   if (value) {
    //     this.showclass = 'asideshow'
    //   } else {
    //     setTimeout(() => {
    //       this.showclass = 'aside'
    //     }, 300)
    //   }
    // })
  },
  methods: {
    changeAsideWidth({ isCollapsing }) {
      this.isCollapsing = isCollapsing
    },
    // 关闭所有
    closeAll() {
      this.$router.push({ path: '/homePage/index' })
      this.$store.commit('setmultiPageLabel', [])
    },
    // 关闭其他
    closeOther() {
      const arr = this.$store.state.multiPageLabelArr
      arr.forEach(item => {
        if (this.activePath === item.path) {
          this.$store.commit('setmultiPageLabel', [{ ...item }])
        }
      })
    },
    // 移除点击的
    handleClose(path) {
      const arr = this.$store.state.multiPageLabelArr
      if (arr.length === 1) {
        this.closeAll()
        return
      }
      let num = 0
      let flag = false
      // 删除当前点击的这一条
      arr.map((item, index) => {
        if (item.path === path) {
          arr.splice(index, 1)
        }
        // 判断删除的这个是不是当前active的
        if (this.activePath === item.path && this.activePath === path) {
          num = index
          flag = true
        }
      })
      if (flag) this.$router.push({ path: arr[num - 1] && arr[num - 1].path || arr[num + 1] && arr[num + 1].path || '/enterpriseInformation/addInformation' })
      this.$store.commit('setmultiPageLabel', arr)
    }
  }
}
</script>
<style lang="scss">
.pageEntryBox {
  height: 100%;
   background: url('../assets/img/main_bg.png');

   .index-con {
    display: flex;
    flex-direction: column;
   }

   .container {
    display: flex;
    flex-direction: row;
    height: calc(100% - 60px);
    // position: absolute;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // transition: 0.5s all;
  }

  .aside {
    // position: absolute;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // z-index: 10;
    transition: 0.5s all;
  }

   .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000000, 0.2);
    z-index: 9;
  }

  .subjectDefinitionVisible {
    .subjectsChoiceBox {
      display: flex;
      align-items: left;
      flex-direction: column;
      padding: 0 24px;

      .customBox {
        display: flex;
        align-items: center;

        p {
          color: #333;
          margin-right: 12px;
          font-weight: 700;
        }
      }

      .colorBox {
        display: flex;
        justify-content: space-between;
        margin: 24px 0;

        span {
          display: inline-block;
          height: 36px;
          width: 36px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    .el-dialog__header {
      display: none;
    }

    .el-drawer__header {
      margin-bottom: 12px;

      >span {
        color: #333;
        font-weight: 900;
        font-size: 18px;
      }
    }
  }

  .el-header {
    position: relative;
  }

 .multiPageLabel {
    height: 32px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    .el-tabs__header {
      height: 100%;
      padding: 0 !important;
      margin: 0 !important;

      .el-tabs__nav {
        height: 32px !important;
        line-height: 32px !important;
      }
    }
    .el-tabs__active-bar{
      display: none !important;
    }

    .el-tabs {
      width: calc(100% - 96px);
      height: 100%;

      .el-tabs__nav-wrap {
        .el-tabs__nav-prev {
          box-shadow: 12px 12px 12px #f2f2f2;
          line-height: 32px;
        }

        .el-tabs__nav-next {
          box-shadow: -12px 0 12px #f2f2f2;
          line-height: 32px;
        }
      }

      .el-tabs__item {
        padding: 0 16px !important;
        height: 32px !important;
        line-height: 32px !important;

        .link {
          color: $mainFontColor;
          text-decoration: none;
          display: inline-block;
          height: 100%;
        }
        &.is-active{
          color: $mainFontColor;
          background-color: #fff;
          border-radius: 4px 4px 0 0;
        }

        .el-icon {
          svg {
            height: 12px;
            width: 12px;
            color: #666666!important;
          }
        }
      }
      &__nav-wrap::after {
        background-color: transparent;
      }
    }

    .refresh-button, .tab-close {
      display: inline-block;
      cursor: pointer;
      width: 38px;
      height: 34px;
      border-radius: 4px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 18px;
        height: 20px;
      }
    }
  }

  .index-con {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  // .aside {
  //   width: 64px !important;
  //   height: 100%;
  //   background-color: #334157;
  //   margin: 0px;
  // }

  // .asideshow {
  //   width: 240px !important;
  //   height: 100%;
  //   background-color: #334157;
  //   margin: 0px;
  // }

  .index-header {
    padding: 0px;
    border-left: 0;
  }

  // .index-main {
  //   position: relative;
  //   .intervalRouterLink{
  //     margin-top: 38px;
  //   }
  // }
}
</style>
