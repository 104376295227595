const orderFinancingRoute = [
  {
    path: '/orderFinancing/productManagement',
    name: 'productManagement',
    component: () => import('../views/orderFinancing/productManagement.vue'),
    meta: {
      name: '产品管理',
      parentPath: '/orderFinancing/productManagement',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/orderFinancing/addProduct',
    name: 'addProduct',
    component: () => import('../views/orderFinancing/children/addProduct.vue'),
    meta: {
      name: '产品新增',
      parentPath: '/orderFinancing/productManagement',
      requireAuth: true
    }
  },
  {
    path: '/orderFinancing/authorizationManagement',
    name: 'authorizationManagement',
    component: () => import('../views/orderFinancing/authorizationManagement.vue'),
    meta: {
      name: '授信管理',
      keepAlive: true,
      parentPath: '/orderFinancing/authorizationManagement',
      requireAuth: true
    }
  },
  {
    path: '/orderFinancing/financeManagement',
    name: 'financeManagement',
    component: () => import('../views/orderFinancing/financeManagement.vue'),
    meta: {
      name: '融资管理',
      parentPath: '/orderFinancing/financeManagement',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/orderFinancing/creditManagementDetails',
    name: 'creditManagementDetails',
    component: () => import('@/views/orderFinancing/children/creditManagementDetails.vue'),
    meta: {
      title: '授信详情',
      parentPath: '/orderFinancing/authorizationManagement',
      requireAuth: true
    }
  },
  {
    path: '/orderFinancing/financeManagementDetails',
    name: 'financeManagementDetails',
    component: () => import('@/views/orderFinancing/children/financeManagementDetails.vue'),
    meta: {
      title: '融资详情',
      parentPath: '/orderFinancing/financeManagement',
      requireAuth: true
    }
  },
  {
    path: '/orderFinancing/productDetails',
    name: 'productDetails',
    component: () => import('@/views/orderFinancing/children/productDetails.vue'),
    meta: {
      title: '产品详情',
      parentPath: '/orderFinancing/productManagement',
      requireAuth: true
    }

  }

]
export default orderFinancingRoute
