import { getRequest, postRequest } from './index'
// 组织结构相关的几个接口
// 机构管理-获取机构 根据用户
export const getOrganizationByMan = (params, successCallback, failureCallback) => {
  getRequest(
    '/organization/list2',
    params,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 获取组织机构的列表
export const getOrganizationList = (successCallback, failureCallback) => {
  getRequest(
    '/organization/list',
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 删除组织机构
export const deleteOrganization = (organizationId, successCallback, failureCallback) => {
  getRequest(
    '/organization/delete?organizationId=' + organizationId,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 添加或者编辑机构
export const addAndUpdateOrganization = (data, successCallback, failureCallback) => {
  postRequest('/organization/addAndUpdate', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 角色相关的接口
// 获取所有角色   不分企业
export const getAllRoles = (roleName, successCallback, failureCallback) => {
  getRequest(
    `/role/allRoles?cmpRole=00&roleName=${roleName}`,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 添加角色
export const addRole = (data, successCallback, failureCallback) => {
  postRequest('/role/addRole', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 编辑角色
export const updateRole = (data, successCallback, failureCallback) => {
  postRequest('/role/updateRole', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 删除角色
export const deleteRole = (roleCode, successCallback, failureCallback) => {
  getRequest('/role/delete?roleCode=' + roleCode, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 根据角色获取它的权限roleCode
export const getRoleOfResource = (roleCode, successCallback, failureCallback) => {
  postRequest('/role/roleOfResource?roleCode=' + roleCode, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 用户的相关接口
// 获取所有用户的接口带分页
export const getUserByPage = (data, successCallback, failureCallback) => {
  postRequest('/user/listbypage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取平台端所有用户的接口带分页
export const listPageBySystem = (data, successCallback, failureCallback) => {
  postRequest('/user/listPageBySystem', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 添加或者编辑用户信息
export const addAndUpdataUser = (data, successCallback, failureCallback) => {
  postRequest(data.password ? '/user/update' : '/user/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 重置密码
export const userResetPassword = (id, successCallback, failureCallback) => {
  getRequest('/user/reset?userId=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 用户的启用与停用
export const userEnabledAndStop = (data, successCallback, failureCallback) => {
  postRequest('/user/userST', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查询所有的权限
export const getAllPermission = (successCallback, failureCallback, roleType) => {
  postRequest(`/role/getResourceSelect?roleType=${roleType || '00'}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 保存角色权限
export const saveRolePermission = (data, successCallback, failureCallback) => {
  postRequest('/role/addAndUpdate', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 权限列表
// 权限列表(带分页)菜单
export const getResourceByPage = (data, successCallback, failureCallback) => {
  postRequest('/role/resourceByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增权限菜单
export const addAndEditPermission = (data, successCallback, failureCallback) => {
  postRequest(data.type === 'edit' ? '/role/resourceUpdate' : '/role/resourceAdd', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 删除权限菜单
export const deletePermission = (data, successCallback, failureCallback) => {
  postRequest('/role/resourceDelete', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 系统日志
// 查询系统日志列表
export const getSyslog = (data, successCallback, failureCallback) => {
  postRequest('/v2/syslog/selectAll', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 字典类型带分页
export const getDictionaryTypePage = (data, successCallback, failureCallback) => {
  getRequest(`/dataDict/selectDictionaryTypePage?pageNum=${data.pageNum || 1}&descrip=${data.descrip || ''}&dictType=${data.dictType || ''}&pageSize=${data.pageSize || 10}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 字典类型带所有的
export const getDictionaryTypeAll = (successCallback, failureCallback) => {
  getRequest('/dataDict/selectDictionaryType', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增和编辑字典类型
export const saveDictionaryType = (data, successCallback, failureCallback) => {
  postRequest('/dataDict/saveDictionaryType', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 删除字典类型
export const deleteDictionaryType = (id, successCallback, failureCallback) => {
  postRequest('/dataDict/deleteDictionaryType', { id }, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 字典数据项带分页
export const getDictionaryDataByPage = (data, successCallback, failureCallback) => {
  getRequest(`/dataDict/selectDictionaryData?pageNum=${data.pageNum || 1}&pageSize=${data.pageSize || 10}&dictType=${data.dictType || ''}&dictId=${data.dictId || ''}&dictName=${data.dictName || ''}&status=${data.status || ''}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增和编辑字典项数据
export const saveDictionaryData = (data, successCallback, failureCallback) => {
  postRequest('/dataDict/saveDictionaryData', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 根据所选系统获取角色列表
export const roleAllRoles = (cmpRole, successCallback, failureCallback) => {
  getRequest('/role/allRoles?cmpRole=' + cmpRole, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业开通系统及角色级联
export const baseinfoSystemAndRole = (successCallback, failureCallback) => {
  getRequest('/cmp/baseinfo/systemAndRole', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业开通系统及角色级联
export const getResourceBySysSelect = (roleType, successCallback, failureCallback) => {
  getRequest('/role/getResourceBySysSelect?roleType=' + roleType, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
