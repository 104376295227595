const shoppingMallRoute = [
  {
    path: '/shoppingMall/orderManage',
    name: 'orderManage',
    component: () => import('../views/shoppingMall/orderManage.vue'),
    meta: {
      name: '订单管理',
      keepAlive: true,
      parentPath: '/shoppingMall/orderManage',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/shopManage',
    name: 'shopManage',
    component: () => import('../views/shoppingMall/shopManage.vue'),
    meta: {
      name: '店铺管理',
      keepAlive: true,
      parentPath: '/shoppingMall/shopManage',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/shopDetail',
    name: 'shopDetail',
    component: () => import('../views/shoppingMall/children/shopDetail.vue'),
    meta: {
      name: '店铺详情',
      parentPath: '/shoppingMall/shopManage',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/orderDetails',
    name: 'orderDetails',
    component: () => import('../views/shoppingMall/children/orderInfo.vue'),
    meta: {
      name: '订单详情',
      parentPath: '/shoppingMall/orderManage',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/listingManagement',
    name: 'listingManagement',
    component: () => import('../views/shoppingMall/listingManagement.vue'),
    meta: {
      name: '挂牌管理',
      keepAlive: true,
      parentPath: '/shoppingMall/listingManagement',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/listingManagementDeatail',
    name: 'listingManagementDeatail',
    component: () => import('../views/shoppingMall/children/listingManagementDeatail.vue'),
    meta: {
      name: '挂牌详情',
      parentPath: '/shoppingMall/listingManagement',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/purchaseManage',
    name: 'purchaseManage',
    component: () => import('../views/shoppingMall/purchaseManage.vue'),
    meta: {
      name: '求购管理',
      keepAlive: true,
      parentPath: '/shoppingMall/purchaseManage',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/purchaseDetails',
    name: 'purchaseDetails',
    component: () => import('../views/shoppingMall/children/purchaseDetails.vue'),
    meta: {
      name: '求购详情',
      parentPath: '/shoppingMall/purchaseManage',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/pickingManage',
    name: 'pickingManage',
    component: () => import('../views/shoppingMall/pickingManage.vue'),
    meta: {
      name: '提货管理',
      keepAlive: true,
      parentPath: '/shoppingMall/pickingManage',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/pickingDetails',
    name: 'pickingDetails',
    component: () => import('../views/shoppingMall/children/pickingDetails.vue'),
    meta: {
      name: '提单详情',
      parentPath: '/shoppingMall/pickingManage',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/transferManage',
    name: 'transferManage',
    component: () => import('../views/shoppingMall/transferManage.vue'),
    meta: {
      name: '过户管理',
      keepAlive: true,
      parentPath: '/shoppingMall/transferManage',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/transferDetails',
    name: 'transferDetails',
    component: () => import('../views/shoppingMall/children/transferDetails.vue'),
    meta: {
      name: '过户详情',
      parentPath: '/shoppingMall/transferManage',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/invoiceManage',
    name: 'invoiceManage',
    component: () => import('../views/shoppingMall/invoiceManage.vue'),
    meta: {
      name: '发票管理',
      keepAlive: true,
      parentPath: '/shoppingMall/invoiceManage',
      requireAuth: true
    }
  },
  {
    path: '/shoppingMall/invoiceDetails',
    name: 'invoiceDetails',
    component: () => import('../views/shoppingMall/children/invoiceDetails.vue'),
    meta: {
      name: '发票详情',
      parentPath: '/shoppingMall/invoiceManage',
      requireAuth: true
    }
  }
]
export default shoppingMallRoute
