<template>
  <div class="fileUploadPageBox">
    <el-upload
      v-if="!file.fileUrl && !file.fileName"
      ref="upload"
      :action="$baseUpload"
      :headers="headers"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
      :on-success="handleAvatarSuccess"
      :on-error="errorUpload"
      :limit="limit"
    >
      <el-button size="small" type="primary">
        上传附件
      </el-button>
    </el-upload>
    <div v-else class="fileBox">
      <span class="elp-1">{{ file.fileName }}</span>
      <el-button type="text" @click="viewFile">
        查看
      </el-button>
      <el-button type="text" @click="downloadFile">
        下载
      </el-button>
      <el-button type="text" @click="clearFile">
        删除
      </el-button>
    </div>
    <div class="file-tips">
      支持扩展名：{{ fileFormat.replace("/",",") }} 文件大小不超过{{ fileSize }}M
    </div>
  </div>
</template>

<script>
import { downloadFile2 } from '@/utils/util'
export default {
  props: {
    editFileName: {
      default: '',
      type: String
    },
    limit: {
      default: 1,
      type: Number
    },
    fileFormat: {
      default: '',
      type: String
    },
    fileSize: {
      default: 10,
      type: Number
    }
  },
  data() {
    return {
      headers: {
        token: localStorage.getItem('logintoken')
      },
      file: { fileName: '', fileUrl: '', fileFormat: '' }
    }
  },
  watch: {
    editFileName: {
      handler(newVal) {
        this.file.fileName = newVal
      }
    }
  },
  methods: {
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < this.fileSize
      const arr = file.name.split('.')
      if (!isLt2M) {
        this.$message.error(`文件大小不能超过 ${this.fileSize}MB!`)
        return false
      }
      if (this.fileFormat && this.fileFormat.indexOf(arr[arr.length - 1].toLowerCase()) < 0) {
        this.$message.error(`上传的格式只能是${this.fileFormat}`)
        return false
      }
      // 获取文件格式
      this.file.fileFormat = arr[arr.length - 1].toLowerCase()
    },
    handleAvatarSuccess(res, file) {
      if (res.code !== 200) {
        this.$message.error(res.msg || res.message)
        this.$refs.upload.clearFiles()
        return
      }
      this.file.fileName = file.name
      this.file.fileUrl = res.data
      this.$emit('getFile', this.file)
    },
    // 查看文件
    viewFile() {
      if (['pdf', 'jpg', 'png', 'jpeg', 'gif', 'psd'].includes(this.file.fileFormat)) {
        window.open(this.$fileUrl + this.file.fileUrl)
        return
      }
      this.$message.error(`暂不支持查看${this.file.fileFormat}文件`)
    },
    // 下载文件
    downloadFile() {
      downloadFile2(this.$fileUrl + this.file.fileUrl, this.file.fileName, this.file.fileFormat)
    },
    // 删除文件
    clearFile() {
      this.file = {
        fileName: '',
        fileUrl: '',
        fileFormat: ''
      }
      this.$emit('getFile', this.file)
    },
    // 上传失败提示
    errorUpload() {
      this.$message.warning('文件上传失败！')
    }
  }
}
</script>
<style lang="scss">
.fileUploadPageBox{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .fileBox{
    display: flex;
    align-items: center;
    >span{
      width: calc(100% - 28px);
      margin-right: 12px;
    }
  }
  .icon{
    padding-left: 18px;
    color: #333;
    font-size: 16px;
    cursor: pointer;
  }
  .icon:hover{
    color: red;
  }
}
.file-tips {
  font-size: 12px;
  color: #666666;
  width: 100%;
  flex-shrink: 0
}
</style>
