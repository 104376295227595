const storageProcessRoute = [
  {
    path: '/storageProcess/processManage',
    name: 'processManage',
    component: () => import('../views//storageProcess/processManage.vue'),
    meta: {
      name: '加工厂管理',
      parentPath: '/storageProcess/processManage',
      requireAuth: true
    }
  },
  {
    path: '/storageProcess/processManageDetails',
    name: 'processManageDetails',
    component: () => import('../views/storageProcess/details/processManageDetails.vue'),
    meta: {
      name: '加工厂详情',
      parentPath: '/storageProcess/processManage',
      requireAuth: true
    }
  },
  {
    path: '/storageProcess/processOrder',
    name: 'processOrder',
    component: () => import('../views/storageProcess/processOrder.vue'),
    meta: {
      name: '加工单列表',
      parentPath: '/storageProcess/processOrder',
      requireAuth: true
    }
  },
  {
    path: '/storageProcess/processOrderDetails',
    name: 'processOrderDetails',
    component: () => import('../views//storageProcess/details/processOrderDetails.vue'),
    meta: {
      name: '加工单详情',
      parentPath: '/storageProcess/processOrder',
      requireAuth: true
    }
  }
]
export default storageProcessRoute
