<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { getSelectAllDict } from './api/login'
import Vue from 'vue'
export default {
  name: 'App',
  async mounted() {
    // 获取字典所有数据呀
    const res = await getSelectAllDict
    const dictionaryEntry = JSON.parse(localStorage.getItem('dictionaryEntry'))
    this.$store.commit('getDictionaryEntry', res.data || dictionaryEntry)
    if (res.data['documentLeadingEdge'] || dictionaryEntry['documentLeadingEdge'])Vue.prototype.$fileUrl = this.$store.getters.getDictionaryItem('documentLeadingEdge')[0].dictName || dictionaryEntry['documentLeadingEdge'][0].dictName
  }
}
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
/* 火狐 */
/* input {
  -moz-appearance: textfield;
} */
.tox-notifications-container {
  display: none !important;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  widows: 100%;
  height: 100%;
}
.elp-1 {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.elp-2 {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.elp-3 {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
@media all and(-ms-high-contrast:none), (-ms-high-contrast: active) {
  .elp-1,
  .elp-2,
  .elp-3 {
    white-space: nowrap;
  }
}
.el-message{
  top: 20px!important;
}
</style>
