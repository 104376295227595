const piaojuRoute = [
  {
    path: '/piaoju/statistics',
    name: 'piaojuStatistics',
    component: () => import('../views/piaoju/statistics/index.vue'),
    meta: {
      name: '票据统计',
      parentPath: '/piaoju/statistics',
      requireAuth: true
    }
  },
  {
    path: '/piaoju/billManage',
    name: 'piaojuBillManage',
    component: () => import('../views/piaoju/billManage/index.vue'),
    meta: {
      name: '票据管理',
      keepAlive: true,
      parentPath: '/piaoju/billManage',
      requireAuth: true
    }
  },
  {
    path: '/piaoju/detailBill',
    name: 'piaojuDetailBill',
    component: () => import('../views/piaoju/billManage/pages/detailBill.vue'),
    meta: {
      name: '票据详情',
      parentPath: '/piaoju/billManage',
      requireAuth: true
    }
  },
  {
    path: '/piaoju/orderManage',
    name: 'piaojuOrderManage',
    component: () => import('../views/piaoju/orderManage/index.vue'),
    meta: {
      name: '票据订单',
      keepAlive: true,
      parentPath: '/piaoju/orderManage',
      requireAuth: true
    }
  },
  {
    path: '/piaoju/detailOrder',
    name: 'piaojuDetailOrder',
    component: () => import('../views/piaoju/orderManage/pages/detailOrder.vue'),
    meta: {
      name: '票据订单详情',
      parentPath: '/piaoju/orderManage',
      requireAuth: true
    }
  },
  {
    path: '/piaoju/channelManage',
    name: 'piaojuChannelManage',
    component: () => import('../views/piaoju/channelManage/index.vue'),
    meta: {
      name: '渠道管理',
      keepAlive: true,
      parentPath: '/piaoju/channelManage',
      requireAuth: true
    }
  },
  {
    path: '/piaoju/channelDetails',
    name: 'piaojuChannelDetails',
    component: () => import('../views/piaoju/channelManage/page/channelDetails.vue'),
    meta: {
      name: '渠道管理详情',
      parentPath: '/piaoju/channelManage',
      requireAuth: true
    }
  },

  {
    path: '/piaoju/customerManage',
    name: 'piaojuCustomerManage',
    component: () => import('../views/piaoju/customerManage/index.vue'),
    meta: {
      name: '客户管理',
      keepAlive: true,
      parentPath: '/piaoju/customerManage',
      requireAuth: true
    }
  }
]
export default piaojuRoute
