import { getRequest, postRequest } from './index'
// 品种映射分页
export const getGoodsMappingList = (data, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsMappingList',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 删除品种映射
export const deleteGoodsMapping = (id, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsMappingDelete',
    { id },
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 新增和编辑品种映射
export const addAndEditGoodsMapping = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    data.id ? '/goods/goodsMappingUpdate' : '/goods/goodsMappingAdd',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 获取系统品种列表  不带分页
export const getGoodsList = (successCallback, failureCallback, goodsName = '') => {
  getRequest(
    '/goods/goodsList?goodsName=' + goodsName,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 根据父元素的code获取下级的品种
export const getChildGoodsList = (
  parentCode,
  successCallback,
  failureCallback
) => {
  getRequest(
    '/goods/goodsListByParentCode?parentCode=' + parentCode,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 根据品名id获取物品件重列表
export const goodsWeightsListByGoodsId = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    `/goods/goodsWeightsListByGoodsId`,
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 品种规格列表
export const getGoodsSpecsList = (data, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsSpecsList',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 品种规格列表带分页
export const getGoodsSpecsListPaging = (data, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsSpecsPage',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 品种规格禁用启用
export const goodsSpecsDelete = (data, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsSpecsDelete',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 品种规格修改与新增
export const addAndEditGoodsSpecs = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    data.id ? '/goods/goodsSpecsUpdate' : '/goods/goodsSpecsAdd',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 产地
// 产地分页列表
export const getGoodsProductionPage = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/goods/goodsProductionPage',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产地禁用启用
export const goodsProductionDelete = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/goods/goodsProductionDelete',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产地修改与新增
export const addAndEditGoodsProduction = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    data.id ? '/goods/goodsProductionUpdate' : '/goods/goodsProductionAdd',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 材质
// 材质的列表
export const getGoodsMaterialsList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/goods/goodsMaterialsList',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 材质禁用启用
export const goodsMaterialsDelete = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/goods/goodsMaterialsDelete',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 材质修改与新增
export const addAndEditGoodsMaterials = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    data.id ? '/goods/goodsMaterialsUpdate' : '/goods/goodsMaterialsAdd',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 物品件重
// 物品件重的列表
export const getGoodsWeightsPage = (data, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsWeightsPage',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 物品件重的删除  禁用  启用
export const goodsProductionStartOrDisableOrDelete = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/goods/goodsProductionStartOrDisableOrDelete',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 物品件重修改与新增
export const addAndEditGoodsWeights = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    data.id ? '/goods/goodsWeightsUpdate' : '/goods/goodsWeightsAdd',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 件重详情
export const goodsWeightsDetail = (id, successCallback, failureCallback) => {
  getRequest(
    '/goods/goodsWeightsDetail?id=' + id,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 品种
// 品种添加
export const goodsAdd = (data, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsAdd',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 品种禁用或者启用
export const goodsDelete = (data, successCallback, failureCallback) => {
  postRequest(
    '/goods/goodsDelete',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 品种禁用或者启用
export const agentPurchaseContractContractInvPage = (data, successCallback, failureCallback) => {
  postRequest(
    '/agentPurchaseContract/contractInvPage',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
