const tradeFinancingRoute = [
  {
    path: '/accountsManage',
    name: 'accountsManage',
    component: () => import('../views/tradeFinancing/accountsManage/index.vue'),
    meta: {
      name: '应收/应付账款管理',
      keepAlive: true,
      parentPath: '/accountsManage',
      requireAuth: true
    }
  },
  {
    path: '/accountsDetails',
    name: 'accountsDetails',
    component: () => import('../views/tradeFinancing/accountsManage/details/accountsDetails.vue'),
    meta: {
      name: '应收/应付账款详情',
      parentPath: '/accountsManage',
      requireAuth: true
    }
  },
  {
    path: '/financeManage/reverseFactoring',
    name: 'reverseFactoring',
    component: () => import('../views/tradeFinancing/financeManage/reverseFactoring.vue'),
    meta: {
      name: '保理融资管理',
      keepAlive: true,
      parentPath: '/financeManage/reverseFactoring',
      requireAuth: true
    }
  },
  {
    path: '/financeManage/reverseFactoringDetail',
    name: 'reverseFactoringDetail',
    component: () => import('../views/tradeFinancing/financeManage/details/reverseFactoringDetails.vue'),
    meta: {
      name: '保理融资详情',
      parentPath: '/financeManage/reverseFactoringDetail',
      requireAuth: true
    }
  },
  {
    path: '/financeManage/reverseFactoringAudit',
    name: 'reverseFactoringAudit',
    component: () => import('../views/tradeFinancing/financeManage/details/reverseFactoringDetails.vue'),
    meta: {
      name: '保理融资审核',
      keepAlive: true,
      parentPath: '/financeManage/reverseFactoringAudit',
      requireAuth: true
    }
  },
  {
    path: '/echainManage/applyRecord',
    name: 'applyRecord',
    component: () => import('../views/tradeFinancing/echainManage/applyRecord/index.vue'),
    meta: {
      name: '信链申请记录',
      keepAlive: true,
      parentPath: '/echainManage/applyRecord',
      requireAuth: true
    }
  },
  {
    path: '/echainManage/applyRecordDetails',
    name: 'applyRecordDetails',
    component: () => import('../views/tradeFinancing/echainManage/applyRecord/applyRecordDetails.vue'),
    meta: {
      name: '信链申请记录详情',
      parentPath: '/echainManage/applyRecordDetails',
      requireAuth: true
    }
  },
  {
    path: '/echainManage/paymentDetails',
    name: 'paymentDetails',
    component: () => import('../views/tradeFinancing/echainManage/applyRecord/paymentDetails.vue'),
    meta: {
      name: '最终付款明细表',
      keepAlive: true,
      parentPath: '/echainManage/paymentDetails',
      requireAuth: true
    }
  },
  {
    path: '/echainManage/echainFinance',
    name: 'echainFinance',
    component: () => import('../views/tradeFinancing/echainManage/echainFinance/index.vue'),
    meta: {
      name: '信链融资管理',
      keepAlive: true,
      parentPath: '/echainManage/echainFinance',
      requireAuth: true
    }
  },
  {
    path: '/echainManage/echainFinanceDetail',
    name: 'echainFinanceDetail',
    component: () => import('../views/tradeFinancing/echainManage/echainFinance/detail.vue'),
    meta: {
      name: '查看详情',
      parentPath: '/echainManage/echainFinanceDetail',
      requireAuth: true
    }
  },
  {
    path: '/echainManage/circulationQuery',
    name: 'circulationQuery',
    component: () => import('../views/tradeFinancing/echainManage/circulationQuery/index.vue'),
    meta: {
      name: '信链路经查询',
      keepAlive: true,
      parentPath: '/echainManage/circulationQuery',
      requireAuth: true
    }
  },
  {
    path: '/echainManage/echainEnrollment',
    name: 'echainEnrollment',
    component: () => import('../views/tradeFinancing/echainManage/echainEnrollment/index.vue'),
    meta: {
      name: '债权转让登记',
      keepAlive: true,
      parentPath: '/echainManage/echainEnrollment',
      requireAuth: true
    }
  },
  {
    path: '/echainManage/echainEnrollmentDetail',
    name: 'echainEnrollmentDetail',
    component: () => import('../views/tradeFinancing/echainManage/echainEnrollment/detail.vue'),
    meta: {
      name: '债权转让登记详情',
      parentPath: '/echainManage/echainEnrollmentDetail',
      requireAuth: true
    }
  },
  {
    path: '/amountManage/core',
    name: 'core',
    component: () => import('../views/tradeFinancing/amountManage/core.vue'),
    meta: {
      name: '核心企业额度',
      keepAlive: true,
      parentPath: '/amountManage/core',
      requireAuth: true
    }
  },
  {
    path: '/amountManage/coreDetail',
    name: 'coreDetail',
    component: () => import('../views/tradeFinancing/amountManage/core/detail.vue'),
    meta: {
      name: '核心企业额度',
      keepAlive: true,
      parentPath: '/amountManage/coreDetail',
      requireAuth: true
    }
  },
  {
    path: '/amountManage/edit',
    name: 'amountManage',
    component: () => import('../views/tradeFinancing/amountManage/core/amountEdit.vue'),
    meta: {
      keepAlive: true,
      name: '额度调整',
      parentPath: '/amountManage/edit',
      requireAuth: true
    }
  },
  {
    path: '/amountManage/supplier',
    name: 'supplier',
    component: () => import('../views/tradeFinancing/amountManage/supplier.vue'),
    meta: {
      name: '供应商额度',
      keepAlive: true,
      parentPath: '/amountManage/supplier',
      requireAuth: true
    }
  },
  {
    path: '/amountManage/group',
    name: 'group',
    component: () => import('../views/tradeFinancing/amountManage/group/index.vue'),
    meta: {
      name: '集团额度',
      parentPath: '/amountManage/group',
      requireAuth: true
    }
  },
  {
    path: '/amountManage/groupQuotaManageDetail',
    name: 'groupQuotaManageDetail',
    component: () => import('../views/tradeFinancing/amountManage/group/detail.vue'),
    meta: {
      name: '额度详情',
      parentPath: '/amountManage/groupQuotaManageDetail',
      requireAuth: true
    }
  },
  {
    path: '/productManage/indexItem',
    name: 'indexItem',
    component: () => import('../views/tradeFinancing/productManage/index.vue'),
    meta: {
      name: '产品管理',
      keepAlive: true,
      parentPath: '/productManage/indexItem',
      requireAuth: true
    }
  },
  {
    path: '/productManage/addProduct',
    name: 'baoli_addProduct',
    component: () => import('../views/tradeFinancing/productManage/addProduct.vue'),
    meta: {
      name: '新增产品',
      parentPath: '/productManage/addProduct',
      requireAuth: true
    }
  },
  {
    path: '/productManage/productAudit',
    name: 'productAudit',
    component: () => import('../views/tradeFinancing/productManage/productDetail/index.vue'),
    meta: {
      name: '产品审核',
      keepAlive: true,
      parentPath: '/productManage/productAudit',
      requireAuth: true
    }
  },
  {
    path: '/productManage/productDetail',
    name: 'productDetail',
    component: () => import('../views/tradeFinancing/productManage/productDetail/index.vue'),
    meta: {
      name: '产品详情',
      parentPath: '/productManage/productDetail',
      requireAuth: true
    }
  },
  {
    path: '/productManage/productEdit',
    name: 'productEdit',
    component: () => import('../views/tradeFinancing/productManage/productEdit.vue'),
    meta: {
      name: '产品编辑',
      parentPath: '/productManage/productDetail',
      requireAuth: true
    }
  },
  {
    path: '/productManage/creditOrganization',
    name: 'creditOrganization',
    component: () => import('../views/tradeFinancing/productManage/creditOrganization/index.vue'),
    meta: {
      name: '授信机构管理',
      keepAlive: true,
      parentPath: '/productManage/creditOrganization',
      requireAuth: true
    }
  },
  {
    path: '/productManage/addCreditOrganization',
    name: 'addCreditOrganization',
    component: () => import('../views/tradeFinancing/productManage/creditOrganization/add.vue'),
    meta: {
      name: '新增授信机构',
      parentPath: '/productManage/addCreditOrganization',
      requireAuth: true
    }
  },
  {
    path: '/productManage/creditOrganizationDetail',
    name: 'creditOrganizationDetail',
    component: () => import('../views/tradeFinancing/productManage/creditOrganization/detail.vue'),
    meta: {
      name: '授信机构详情',
      parentPath: '/productManage/creditOrganizationDetail',
      requireAuth: true
    }
  },
  {
    path: '/productManage/editCreditOrganization',
    name: 'editCreditOrganization',
    component: () => import('../views/tradeFinancing/productManage/creditOrganization/add.vue'),
    meta: {
      name: '编辑授信机构',
      parentPath: '/productManage/editCreditOrganization',
      requireAuth: true
    }
  },
  {
    path: '/productManage/guarantProduct',
    name: 'guarantProduct',
    component: () => import('../views/tradeFinancing/productManage/guarantProduct/index.vue'),
    meta: {
      name: '担保产品管理',
      keepAlive: true,
      parentPath: '/productManage/guarantProduct',
      requireAuth: true
    }
  },
  {
    path: '/productManage/addGuarantProduct',
    name: 'addGuarantProduct',
    component: () => import('../views/tradeFinancing/productManage/guarantProduct/add.vue'),
    meta: {
      name: '新增担保产品',
      parentPath: '/productManage/addGuarantProduct',
      requireAuth: true
    }
  }

]
export default tradeFinancingRoute
