<template>
  <el-tree class="treeLinePageBox" :accordion="true" :data="treeData" :props="defaultProps" @node-expand="handleNodeClick" @node-click="handleNodeClick" />
</template>

<script>
export default {
  props: {
    treeData: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'goodsName'
      }
    }
  },
  methods: {
    handleNodeClick(el, node) {
      this.$emit('getTreeData', { level: node.level, code: el.code, row: el })
    }
  }
}
</script>

<style lang="scss" scoped>
.treeLinePageBox{
  padding-right: 12px;
}
</style>
