const codes = {
  // 权限菜单类型-角色类型
  resTypeId: [{
    value: '222',
    text: '业务类'
  }, {
    value: '221',
    text: '管理类'
  }],
  // 权限菜单使用类型
  resOperTypeId: [{
    value: '1',
    text: '目录'
  }, {
    value: '2',
    text: '菜单'
  }, {
    value: '3',
    text: '按钮'
  }],
  // 期限单位
  termunit: [{
    value: '10',
    text: '年'
  }, {
    value: '20',
    text: '月'
  }, {
    value: '30',
    text: '日'
  }],
  // 还款方式
  retukind: [{
    value: '',
    text: '全部'
  }, {
    value: '01',
    text: '等本等息'
  }, {
    value: '02',
    text: '按月付息，分期还本'
  }, {
    value: '03',
    text: '按月付息，到期还本'
  }, {
    value: '04',
    text: '一次性还款'
  }, {
    value: '05',
    text: '等额本金'
  }, {
    value: '06',
    text: '等额本息'
  }],
  // 单据类型
  billType: [{
    value: '01',
    text: '采购订单'
  }, {
    value: '02',
    text: '采购到货单'
  }, {
    value: '03',
    text: '采购入库单'
  }, {
    value: '04',
    text: '采购退货单'
  }, {
    value: '05',
    text: '采购对账单'
  }, {
    value: '06',
    text: '采购发票'
  }, {
    value: '07',
    text: '采购应付单'
  }, {
    value: '08',
    text: '采购付款单'
  }, {
    value: '09',
    text: '销售订单'
  }, {
    value: '10',
    text: '销售发货单'
  }, {
    value: '11',
    text: '销售出库单'
  }, {
    value: '12',
    text: '销售退货单'
  }, {
    value: '13',
    text: '销售对账单'
  }, {
    value: '14',
    text: '销售发票'
  }, {
    value: '15',
    text: '销售应收单'
  }, {
    value: '16',
    text: '销售收款单'
  }, {
    value: '17',
    text: '其他出库单'
  }, {
    value: '18',
    text: '其他入库单'
  }],
  // 单据状态
  billStat: [{
    value: '',
    text: '全部'
  }, {
    value: '01',
    text: '未确认'
  }, {
    value: '02',
    text: '待确认'
  }, {
    value: '03',
    text: '已确认'
  }],
  // 是否有过拖欠/逾期标识
  overflag: [{
    value: '01',
    text: '未拖欠'
  }, {
    value: '02',
    text: '拖欠'
  }, {
    value: '03',
    text: '待转逾期'
  }],
  // 币种
  proCurrency: [{
    value: 'CNY',
    text: '人民币'
  }, {
    value: 'CFC',
    text: '记帐法国法郎'
  }, {
    value: 'CSF',
    text: '记帐瑞士法郎'
  }, {
    value: 'CUS',
    text: '记帐美元'
  }, {
    value: 'CUR',
    text: '清算卢布'
  }, {
    value: 'CPS',
    text: '记帐英镑'
  }, {
    value: 'UDM',
    text: '蒙古美元'
  }, {
    value: 'AED',
    text: '阿联酋迪拉姆'
  }, {
    value: 'ALL',
    text: '阿尔尼亚列克'
  }, {
    value: 'DZD',
    text: '阿尔及利亚第纳'
  }, {
    value: 'AUD',
    text: '澳大利亚元'
  }, {
    value: 'ATS',
    text: '奥地利先令'
  }, {
    value: 'BDT',
    text: '孟加拉国塔卡'
  }, {
    value: 'BEF',
    text: '比利时法郎'
  }, {
    value: 'BHD',
    text: '巴林第纳尔'
  }, {
    value: 'BIF',
    text: '布隆迪法郎'
  }, {
    value: 'BGL',
    text: '保加利亚列瓦'
  }, {
    value: 'BUK',
    text: '缅甸币'
  }, {
    value: 'BWP',
    text: '博茨瓦纳普拉'
  }, {
    value: 'CAD',
    text: '加拿大元'
  }, {
    value: 'CYP',
    text: '塞浦路斯镑'
  }, {
    value: 'CSK',
    text: '捷克克朗'
  }, {
    value: 'KPW',
    text: '朝鲜币'
  }, {
    value: 'DKK',
    text: '丹麦克朗'
  }, {
    value: 'DEM',
    text: '德国马克'
  }, {
    value: 'NLG',
    text: '荷兰盾'
  }, {
    value: 'EGP',
    text: '埃及镑'
  }, {
    value: 'ETB',
    text: '埃塞俄比亚比尔'
  }, {
    value: 'EUR',
    text: '欧元'
  }, {
    value: 'XEU',
    text: '欧洲货币单位'
  }, {
    value: 'FIM',
    text: '芬兰马克'
  }, {
    value: 'FRF',
    text: '法国法郎'
  }, {
    value: 'GHC',
    text: '加纳塞地'
  }, {
    value: 'XAU',
    text: '黄金'
  }, {
    value: 'GRD',
    text: '希腊德拉马克'
  }, {
    value: 'GNS',
    text: '几内亚西里'
  }, {
    value: 'HKD',
    text: '港币'
  }, {
    value: 'HUF',
    text: '匈牙利福林'
  }, {
    value: 'IDR',
    text: '印度尼西亚卢比'
  }, {
    value: 'INR',
    text: '印度罗比'
  }, {
    value: 'IRR',
    text: '伊朗里亚尔'
  }, {
    value: 'IQD',
    text: '伊拉克地纳尔'
  }, {
    value: 'ITL',
    text: '意大利里拉'
  }, {
    value: 'JPY',
    text: '日元'
  }, {
    value: 'JOD',
    text: '约旦第纳尔'
  }, {
    value: 'KES',
    text: '肯尼亚先令'
  }, {
    value: 'KWD',
    text: '科威特第纳尔'
  }, {
    value: 'LYD',
    text: '利比亚第纳尔'
  }, {
    value: 'MOP',
    text: '澳门币'
  }, {
    value: 'MYR',
    text: '马币'
  }, {
    value: 'MLF',
    text: '马里法郎'
  }, {
    value: 'MGF',
    text: '马达加斯加法郎'
  }, {
    value: 'MNT',
    text: '蒙古图格里克'
  }, {
    value: 'MAD',
    text: '摩洛哥地拉姆'
  }, {
    value: 'MUR',
    text: '毛里求斯卢比'
  }, {
    value: 'NPR',
    text: '尼泊尔卢比'
  }, {
    value: 'NZD',
    text: '新西兰元'
  }, {
    value: 'NGN',
    text: '尼日利亚奈拉'
  }, {
    value: 'NOK',
    text: '挪威克朗'
  }, {
    value: 'PKR',
    text: '巴基斯坦卢比'
  }, {
    value: 'PGK',
    text: '巴布亚新基那'
  }, {
    value: 'PHP',
    text: '菲律宾比索'
  }, {
    value: 'PLZ',
    text: '波兰兹罗提'
  }, {
    value: 'GBP',
    text: '英镑'
  }, {
    value: 'ROL',
    text: '罗马尼亚列依'
  }, {
    value: 'RWF',
    text: '卢旺达法郎'
  }, {
    value: 'SCR',
    text: '塞舌尔卢比'
  }, {
    value: 'SDP',
    text: '苏丹镑'
  }, {
    value: 'SLL',
    text: '塞拉利昂'
  }, {
    value: 'XAG',
    text: '白银'
  }, {
    value: 'SGD',
    text: '新加坡元'
  }, {
    value: 'ESP',
    text: '西班牙比塞塔'
  }, {
    value: 'XDR',
    text: '特别提款权'
  }, {
    value: 'LKR',
    text: '斯里兰卡卢比'
  }, {
    value: 'SEK',
    text: '瑞典克朗'
  }, {
    value: 'CHF',
    text: '瑞士法郎'
  }, {
    value: 'SYP',
    text: '叙利亚镑'
  }, {
    value: 'THB',
    text: '泰币'
  }, {
    value: 'TZS',
    text: '坦桑尼亚先令'
  }, {
    value: 'TND',
    text: '突尼斯第纳尔'
  }, {
    value: 'TRL',
    text: '土耳其里拉'
  }, {
    value: 'USD',
    text: '美元'
  }, {
    value: 'SUR',
    text: '苏联卢布'
  }, {
    value: 'VND',
    text: '越南盾'
  }, {
    value: 'KRW',
    text: '韩元'
  }, {
    value: 'XOF',
    text: '西非共同体法郎'
  }, {
    value: 'YER',
    text: '也门里亚尔'
  }, {
    value: 'ZMK',
    text: '赞比亚克瓦查'
  }, {
    value: 'MXP',
    text: '墨西哥比索'
  }, {
    value: 'RUB',
    text: '俄国卢布'
  }, {
    value: 'BRL',
    text: '巴西里亚尔'
  }, {
    value: 'TWD',
    text: '台湾元'
  }],
  // 账户类型 保理专户、保证金账户、结算账户、对公账户
  acctype: [{
    value: '0100',
    text: '对公账户'
  }, {
    value: '0200',
    text: '保理专户'
  }, {
    value: '0300',
    text: '保证金账户'
  }, {
    value: '0400',
    text: '结算账户'
  }],
  // 银行代码
  bankcode: [{
    value: 'ICBC',
    text: '中国工商银行'
  }, {
    value: 'CCB',
    text: '中国建设银行'
  }, {
    value: 'ABC',
    text: '中国农业银行'
  }, {
    value: 'BOC',
    text: '中国银行'
  }, {
    value: 'BCOM',
    text: '中国交通银行'
  }, {
    value: 'CMB',
    text: '招商银行'
  }, {
    value: 'CEB',
    text: '中国光大银行'
  }, {
    value: 'RCB',
    text: '农信银'
  }, {
    value: 'POST',
    text: '中国邮政储蓄银行'
  }, {
    value: 'SDB',
    text: '深圳发展银行'
  }, {
    value: 'CGB',
    text: '广东发展银行'
  }, {
    value: 'CIB',
    text: '中国兴业银行'
  }, {
    value: 'CITIC',
    text: '中信银行'
  }, {
    value: 'SPDB',
    text: '上海浦东发展银行'
  }, {
    value: 'CMBC',
    text: '中国民生银行'
  }, {
    value: 'PAB',
    text: '平安银行'
  }, {
    value: 'HXB',
    text: '华夏银行'
  }, {
    value: 'SHB',
    text: '上海银行'
  }, {
    value: 'GZRCB',
    text: '广州农村商业银行'
  }, {
    value: 'HSB',
    text: '徽商银行'
  }, {
    value: 'BHB',
    text: '渤海银行'
  }, {
    value: 'JSB',
    text: '江苏银行'
  }],
  // 授信来源
  proFundRes: [
  //   {
  //   value: '',
  //   text: '全部'
  // },
    {
      value: '01',
      text: '平台授信'
    }, {
      value: '02',
      text: '机构授信'
    }],
  // 额度状态
  credStat: [{
    value: '',
    text: '全部'
  }, {
    value: '01',
    text: '未生效'
  }, {
    value: '02',
    text: '已生效'
  }, {
    value: '03',
    text: '已失效'
  }, {
    value: '04',
    text: '已冻结'
  }, {
    value: '05',
    text: '风险控制'
  }, {
    value: '06',
    text: '无'
  }],
  // 查询条件额度状态
  searchCredStat: [{
    value: '',
    text: '全部'
  }, {
    value: '01',
    text: '未生效'
  }, {
    value: '02',
    text: '已生效'
  }, {
    value: '03',
    text: '已失效'
  }, {
    value: '04',
    text: '已冻结'
  }, {
    value: '05',
    text: '风险控制'
  }],
  // 业务类型
  credBusiType: [{
    value: '00',
    text: '报告申请'
  }, {
    value: '01',
    text: '额度申请'
  }, {
    value: '02',
    text: '额度冻结'
  }, {
    value: '03',
    text: '额度解冻'
  }, {
    value: '04',
    text: '额度终止'
  }, {
    value: '05',
    text: '额度申请撤销'
  }, {
    value: '06',
    text: '额度冻结申请撤销'
  }, {
    value: '07',
    text: '额度解冻申请撤销'
  }, {
    value: '08',
    text: '额度终止申请撤销'
  }, {
    value: '09',
    text: '风控额度冻结'
  }, {
    value: '10',
    text: '风控额度解冻'
  }, {
    value: '11',
    text: '日终自动终止额度'
  }],
  // 费用类型
  costType: [{
    value: '',
    text: '全部'
  }, {
    value: '00',
    text: '平台类'
  }, {
    value: '01',
    text: '业务费'
  }, {
    value: '02',
    text: '服务费'
  }, {
    value: '0201',
    text: '服务费-买方'
  }],
  // 收取方式
  collectType: [{
    value: '',
    text: '全部'
  }, {
    value: '00',
    text: '%'
  }, {
    value: '01',
    text: '元/笔'
  }, {
    value: '02',
    text: '%年化'
  }],
  // 收取方式2
  collectTypeTwo: [{
    value: '00',
    text: '%'
  }, {
    value: '01',
    text: '元/笔'
  }, {
    value: '02',
    text: '%年化'
  }],
  // 保理费收取方式
  factoringType: [{
    value: '0',
    text: '按笔'
  }, {
    value: '1',
    text: '按年'
  }, {
    value: '2',
    text: '不收取'
  }],
  // 产品融资利息是否前置收取
  preCharge: [{
    value: '00',
    text: '是'
  }, {
    value: '01',
    text: '否'
  }],
  // 收取方角色
  costOwner: [{
    value: '',
    text: '全部'
  }, {
    value: '00',
    text: '平台'
  }, {
    value: '01',
    text: '资方'
  }],
  // 收取时点
  collectTime: [{
    value: '00',
    text: '融资申请前收取'
  }, {
    value: '01',
    text: '放款前收取'
  }, {
    value: '02',
    text: '逾期时收取'
  }, {
    value: '03',
    text: '提前还款时收取'
  }, {
    value: '04',
    text: '催收时收取'
  }, {
    value: '05',
    text: '服务使用时收取'
  }, {
    value: '06',
    text: '展期时收取'
  }],
  // 费用设置的收取时点
  costGentime: [{
    value: '01',
    text: '放款前收取'
  }, {
    value: '04',
    text: '催收时收取'
  }, {
    value: '05',
    text: '服务使用时收取'
  }],
  // 应用范围
  costUserange: [{
    value: '',
    text: '全部'
  }, {
    value: '00',
    text: '小贷'
  }, {
    value: '01',
    text: '银行'
  }],
  // 费用状态
  costState: [{
    value: '',
    text: '全部'
  }, {
    value: '00',
    text: '已启用'
  }, {
    value: '01',
    text: '已停用'
  }],
  // 节假日状态
  holidayState: [{
    value: '',
    text: '全部'
  }, {
    value: '00',
    text: '节假日'
  }, {
    value: '01',
    text: '工作日'
  }],
  // 流程类别
  activitetype: [{
    value: '',
    text: '全部'
  }, {
    value: 10,
    text: '会员信息修改审批'
  }, {
    value: 20,
    text: '产品新增审批'
  }, {
    value: 30,
    text: '额度申请审批'
  }, {
    value: 40,
    text: '额度冻结申请审批'
  }, {
    value: 50,
    text: '额度解冻申请审批'
  }, {
    value: 60,
    text: '额度终止申请审批'
  }, {
    value: 70,
    text: '融资申请审批'
  }, {
    value: 80,
    text: '反向保理融资审批'
  }],
  // 产品类别
  proCate: [
    // {
    //   value: '',
    //   text: '全部'
    // },
    {
      value: '01',
      text: '应收账款类',
      children: [
        {
          value: '0101',
          text: '保理'
        }, {
          value: '0102',
          text: '应收账款质押融资'
        }, {
          value: '0103',
          text: '未来应收账款质押融资'
        }, {
          value: '0104',
          text: '订单融资'
        }, {
          value: '0105',
          text: '发票融资'
        }, {
          value: '0106',
          text: '反向保理'
        }]
    },
    {
      value: '02',
      text: '存货类',
      children: [
        {
          value: '0201',
          text: '仓单融资'
        }, {
          value: '0202',
          text: '存货融资'
        }, {
          value: '0203',
          text: '融通仓'
        }]
    },
    {
      value: '03',
      text: '预付款类',
      children: [
        {
          value: '0301',
          text: '保兑仓'
        }]
    },
    {
      value: '04',
      text: '征信类',
      children: [
        {
          value: '0401',
          text: '征信类'
        }]
    }],
  // 产品状态
  proState: [{
    value: '',
    text: '全部'
  }, {
    value: '01',
    text: '待启用'
  }, {
    value: '02',
    text: '已启用'
  }, {
    value: '03',
    text: '已作废'
  }, {
    value: '04',
    text: '已停用'
  }],
  // 产品审核状态
  proCheckFlag: [{
    value: '01',
    text: '待提交'
  }, {
    value: '02',
    text: '待运营方审核'
  }, {
    value: '03',
    text: '运营方审核中'
  }, {
    value: '04',
    text: '运营方审核通过'
  }, {
    value: '05',
    text: '运营方审核不通过'
  }],
  // 明暗标识
  proMaFlag: [{
    value: '1',
    text: '明保理'
  }, {
    value: '0',
    text: '暗保理'
  }],
  // 有无追索权
  proZsFlag: [{
    value: '1',
    text: '有'
  }, {
    value: '0',
    text: '无'
  }],
  // 是否允许提前还款
  proPrepaymentFlag: [{
    value: '1',
    text: '允许'
  }, {
    value: '0',
    text: '不允许'
  }],
  // 额度可循环
  credCycFlag: [{
    value: '***',
    text: '***'
  }, {
    value: '1',
    text: '是'
  }, {
    value: '0',
    text: '否'
  }],
  // 是否宽限
  proGraceFlag: [{
    value: '1',
    text: '是'
  }, {
    value: '0',
    text: '否'
  }],

  // 当前是否逾期-是否有历史逾期记录
  whether: [{
    value: '',
    text: '全部'
  }, {
    value: '1',
    text: '是'
  }, {
    value: '0',
    text: '否'
  }],
  // 还款单状态
  retuStat: [{
    value: '00',
    text: '正常'
  }, {
    value: '01',
    text: '逾期'
  }, {
    value: '02',
    text: '结清'
  }],
  // 提前还款单状态
  preRetuStat: [{
    value: '00',
    text: '未入账'
  }, {
    value: '01',
    text: '已入账'
  }, {
    value: '02',
    text: '作废'
  }],
  // 融资单状态
  financeOrderState: [{
    value: '',
    text: '全部'
  }, {
    value: '00',
    text: '待还款'
  }, {
    value: '01',
    text: '部分还款'
  }, {
    value: '02',
    text: '已结清'
  }],
  // 单据转让状态
  billTransferState: [
    {
      value: '',
      text: '全部'
    },
    {
      value: '03',
      text: '未转让'
    },
    {
      value: '01',
      text: '待转让'
    }, {
      value: '02',
      text: '已转让'
    }],
  // 账号审核状态
  cmpAuditStat: [{
    value: '',
    text: '全部'
  }, {
    value: '01', //
    text: '待提交'
  }, {
    value: '02', //
    text: '已提交'
  }, {
    value: '03',
    text: '风控待审核'
  }, {
    value: '04', //
    text: '风控审核不通过'
  }, {
    value: '05',
    text: '待运营方审核'
  }, {
    value: '06',
    text: '运营方审核中'
  }, {
    value: '07', //
    text: '运营方审核通过'
  }, {
    value: '08', //
    text: '运营方审核不通过'
  }, {
    value: '09', //
    text: '资金方审核中'
  }, {
    value: '10', //
    text: '资金方审核不通过'
  }],
  // 账号状态
  cmpIdStat: [{
    value: '',
    text: '全部'
  }, {
    value: '01',
    text: '未激活'
  }, {
    value: '02',
    text: '已激活'
  }, {
    value: '03',
    text: '已停用'
  }],
  // 放款状态
  loanState: [{
    value: '',
    text: '全部'
  }, {
    value: '00',
    text: '待放款'
  }, {
    value: '01',
    text: '已放款'
  }],
  // 风险等级
  riskGrade: [{
    value: '',
    text: '全部'
  }, {
    value: '01',
    text: '蓝色预警'
  }, {
    value: '02',
    text: '黄色预警'
  }, {
    value: '03',
    text: '红色预警'
  }],
  // 融资申请状态
  appStat: [{
    value: '',
    text: '全部'
  }, {
    value: '01',
    text: '风控待审核'
  }, {
    value: '02',
    text: '融资信息待补录'
  }, {
    value: '03',
    text: '风控拒绝'
  }, {
    value: '04',
    text: '待资金方审核'
  }, {
    value: '05',
    text: '融资信息补录失败'
  }, {
    value: '06',
    text: '资金方审核中'
  }, {
    value: '07',
    text: '资金方审核不通过'
  }, {
    value: '08',
    text: '待融资企业签署'
  }, {
    value: '09',
    text: '融资企业拒绝签署'
  }, {
    value: '10',
    text: '待核心企业签署'
  }, {
    value: '11',
    text: '核心企业拒绝签署'
  }, {
    value: '12',
    text: '待资金方签署'
  }, {
    value: '13',
    text: '资金方同意签署'
  }, {
    value: '14',
    text: '资金方拒绝签署'
  }, {
    value: '15',
    text: '融资申请已撤销'
  }],
  // 业务审核/风控状态
  credBusiStat: [{
    value: '01',
    text: '风控待审核'
  }, {
    value: '02',
    text: '待运营方审核'
  }, {
    value: '03',
    text: '风控拒绝'
  }, {
    value: '04',
    text: '运营方审核中'
  }, {
    value: '05',
    text: '待资金方审核'
  }, {
    value: '06',
    text: '运营方审核不通过'
  }, {
    value: '07',
    text: '资金方审核中'
  }, {
    value: '08',
    text: '待融资企业签署'
  }, {
    value: '09',
    text: '资金方审核不通过'
  }, {
    value: '10',
    text: '融资企业同意签署'
  }, {
    value: '11',
    text: '融资企业拒绝签署'
  }, {
    value: '12',
    text: '自动终止额度成功'
  }, {
    value: '13',
    text: '资金方审核通过'
  }, {
    value: '14',
    text: '风控冻结成功'
  }, {
    value: '15',
    text: '风控解冻成功'
  }, {
    value: '16',
    text: '额度申请已撤销'
  }, {
    value: '17',
    text: '额度冻结申请已撤销'
  }, {
    value: '18',
    text: '额度解冻申请已撤销'
  }, {
    value: '19',
    text: '额度终止申请已撤销'
  }, {
    value: '20',
    text: '运营方审核通过'
  }],
  // 企业角色
  cmpRole: [{
    value: '01',
    text: '核心企业'
  }, {
    value: '02',
    text: '供应商'
  }, {
    value: '03',
    text: '经销商'
  }],
  // 企业角色
  cmpRole1: [{
    value: '01',
    text: '01'
  }, {
    value: '02',
    text: '02'
  }, {
    value: '03',
    text: '03'
  }],
  // 合同类别
  compactType: [{
    value: '01',
    text: '授信协议'
  }, {
    value: '02',
    text: '应收账款转让协议'
  }, {
    value: '03',
    text: '应收账款转让通知书'
  }, {
    value: '04',
    text: '融资协议'
  }, {
    value: '05',
    text: '注册告知书'
  }, {
    value: '06',
    text: '借款借据'
  }],
  // 单据详情字段
  billDetailItem: [{
    value: 'billNo',
    text: '单据编号'
  }, {
    value: 'buyCmpName',
    text: '企业名称（买方）'
  }, {
    value: 'sellCmpName',
    text: '企业名称（卖方）'
  }, {
    value: 'tranContNo',
    text: '贸易合同编号'
  }, {
    value: 'issuDate',
    text: '单据开票日'
  }, {
    value: 'acctDate',
    text: '单据结算日'
  }, {
    value: 'billAmt',
    text: '单据金额'
  }, {
    value: 'billStat',
    text: '单据状态'
  }, {
    value: 'transStat',
    text: '单据转让状态'
  }, {
    value: 'mateName',
    text: '材料名称'
  }, {
    value: 'speCifiCat',
    text: '规格'
  }, {
    value: 'model',
    text: '型号'
  }, {
    value: 'unit',
    text: '单位'
  }, {
    value: 'quantity',
    text: '数量'
  }, {
    value: 'price',
    text: '价格'
  }, {
    value: 'planReceDate',
    text: '计划收货日期'
  }, {
    value: 'billBatchNo',
    text: '单据批次号'
  }, {
    value: 'createUser',
    text: '创建人'
  }, {
    value: 'createTime',
    text: '创建日期'
  }, {
    value: 'modifyUser',
    text: '修改人'
  }, {
    value: 'modifyTime',
    text: '修改日期'
  }, {
    value: 'actQuantity',
    text: '实收数量'
  }, {
    value: 'purOrderNo',
    text: '采购订单号'
  }, {
    value: 'wareHouseId',
    text: '仓库编码'
  }, {
    value: 'wareHouseName',
    text: '仓库名称'
  }, {
    value: 'purArrivNo',
    text: '到货单号'
  }, {
    value: 'getOutDate',
    text: '出库日期'
  }, {
    value: 'checkBeginDate',
    text: '对账开始日期'
  }, {
    value: 'checkEndDate',
    text: '对账结束日期'
  }, {
    value: 'purInvoiceNo',
    text: '发票号'
  }, {
    value: 'goodsName',
    text: '商品名称'
  }, {
    value: 'sellOrderNo',
    text: '销售订单号'
  }, {
    value: 'actQuantity',
    text: '实发数量'
  }, {
    value: 'sellSendNo',
    text: '发货单号'
  }, {
    value: 'sellInvoiceNo',
    text: '销售发票号'
  }, {
    value: 'sellInvoiceDate',
    text: '销售发票日期'
  }, {
    value: 'prodOrderNo',
    text: '生产订单号'
  }, {
    value: 'prodDate',
    text: '生产日期'
  }],
  // 贷款用途
  loanUse: [{
    value: '01',
    text: '固定资产投资'
  }, {
    value: '02',
    text: '购买原材料'
  }, {
    value: '03',
    text: '技术改造'
  }, {
    value: '04',
    text: '流动资金周转'
  }, {
    value: '05',
    text: '购买经营设备'
  }, {
    value: '06',
    text: '经营场所装修'
  }, {
    value: '07',
    text: '场地租金支付'
  }],
  // 审核意见
  approveResult: [{
    value: 'approve_pass',
    text: '审核通过'
  }, {
    value: 'approve_refuse',
    text: '审核拒绝'
  }, {
    value: 'approve_back',
    text: '审核回退'
  }],
  // 关联企业情况统计
  getCmpreleinfo: [
    {
      value: '01',
      text: '企业直接对外投资'
    },
    {
      value: '02',
      text: '法定代表人对外投资'
    },
    {
      value: '03',
      text: '法定代表人在外任职'
    },
    {
      value: '04',
      text: '企业高管对外投资'
    },
    {
      value: '05',
      text: '企业高管在外任职'
    }
  ],
  // 数据来源，0-全部 1-系统录入，2-ERP
  dataSource: [
    {
      value: '',
      text: '全部'
    },
    {
      value: 1,
      text: '系统录入'
    },
    {
      value: 2,
      text: 'ERP'
    }
  ],
  // 单据转让状态01:待转让02:已转让03:未转让,04:部分转让
  transStat: [
    {
      value: '',
      text: '全部'
    },
    {
      value: '01',
      text: '待转让'
    },
    {
      value: '02',
      text: '已转让'
    },
    {
      value: '03',
      text: '未转让'
    },
    {
      value: '04',
      text: '部分转让'
    }
  ],
  // 操作类型list 0.登陆 1.新增，2.修改，3.删除
  operTypeList: [
    {
      value: '',
      text: '全部'
    },
    {
      value: 0,
      text: '登录'
    },
    {
      value: 1,
      text: '新增'
    },
    {
      value: 2,
      text: '修改'
    },
    {
      value: 3,
      text: '删除'
    }
  ],
  // 业务类型list 1.登陆，2.产品，3.额度，4.融资
  busiTypeList: [
    {
      value: '',
      text: '全部模块'
    },
    {
      value: 1,
      text: '登录模块'
    },
    {
      value: 2,
      text: '产品模块'
    },
    {
      value: 3,
      text: '额度模块'
    },
    {
      value: 4,
      text: '融资模块'
    }
  ],
  // 收款/付款状态，01：未付款，02：部分付款，03：已付款
  moneyState: [{
    value: '',
    text: '全部'
  }, {
    value: '01',
    text: '未收款/付款'
  }, {
    value: '02',
    text: '部分收款/付款'
  }, {
    value: '03',
    text: '已收款/付款'
  }],
  // 付款状态，01：未付款，02：部分付款，03：已付款
  moneyState1: [{
    value: '',
    text: '全部'
  }, {
    value: '01',
    text: '未付款'
  }, {
    value: '02',
    text: '部分付款'
  }, {
    value: '03',
    text: '已付款'
  }],

  // 反向保理融资状态
  // 融资申请状态 01:待融资企业签署,02:融资企业拒绝签署,03:待核心企业签署,04:核心企业拒绝签署,
  // 05:待运营方初审,06:待运营方复审,07:运营方审核不通过,08:待资金方审核,09:资金方审核不通过,
  // 10:待放款,11:待还款,12:已结清
  appStat4ReverseFinacing: [
    {
      value: '',
      text: '全部'
    },
    {
      value: '01',
      text: '待融资企业签署'
    },
    {
      value: '02',
      text: '融资企业拒绝签署'
    },
    {
      value: '03',
      text: '待核心企业签署'
    },
    {
      value: '04',
      text: '核心企业拒绝签署'
    },
    {
      value: '05',
      text: '待运营方初审'
    },
    {
      value: '06',
      text: '待运营方复审'
    },
    {
      value: '07',
      text: '运营方审核不通过'
    },
    {
      value: '08',
      text: '待资金方审核'
    },
    {
      value: '09',
      text: '资金方审核不通过'
    },
    {
      value: '10',
      text: '待放款'
    },
    {
      value: '11',
      text: '待还款'
    },
    {
      value: '12',
      text: '已结清'
    },
    {
      value: '13',
      text: '超时自动终止'
    }
  ],
  // 角色管理-使用平台 00：默认自己所属企业，0101：核心企业，0102：融资企业，02：资金方
  usePlatform: [
    {
      value: '00',
      text: '默认自己所属企业'
    },
    {
      value: '0101',
      text: '核心企业'
    },
    {
      value: '0102',
      text: '融资企业'
    },
    {
      value: '02',
      text: '资金方'
    }
  ],
  // 流程配置-是否启用0：不启用，1:启用
  enable: [
    {
      value: 0,
      text: '不启用'
    },
    {
      value: 1,
      text: '启用'
    }
  ],
  stat: [
    {
      value: '01',
      text: '有效'
    },
    {
      value: '02',
      text: '无效'
    }
  ],
  // 开票接收类型 00电子，01纸质
  receiveType: [
    {
      value: '00',
      text: '电子'
    },
    {
      value: '01',
      text: '纸质'
    }
  ],
  // 发票状态 00普票，01专票
  invoiceType: [
    {
      value: '00',
      text: '普票'
    },
    {
      value: '01',
      text: '专票'
    }
  ]

}
export default {
  // 根据value获取对应的text
  getTextByCode(type, value) {
    if (!value && value !== 0) return '-'
    const stat = codes[type].every(item => {
      return item['value'] !== value
    })
    if (stat) {
      return '-'
    } else {
      return codes[type].filter(item => {
        return item['value'] === value
      })[0]['text']
    }
  },
  // 根据dictId获取data中对应的dictName
  getDictNameByData(data, dictId) {
    if (!dictId && dictId !== 0) return '-'
    // eslint-disable-next-line eqeqeq
    const obj = data.find(item => item.dictId == dictId) || { dictName: '-' }
    return obj.dictName
  },
  // select下拉框
  getSelectByCode(type) {
    return codes[type]
  },
  // 获取chidlren 二级联动select
  getChildrenByCode(type, value) {
    if (!value) return []
    const stat = codes[type].every(item => {
      return item['value'] !== value
    })
    if (stat) {
      return []
    } else {
      return codes[type].filter(item => {
        return item['value'] === value
      })[0]['children']
    }
  },
  // 根据二级联动select的value获取对应的text
  getChildrenTextByCode(type, value, childValue) {
    if (!childValue) return '-'
    const stat = codes[type].every(item => {
      return item['value'] !== value
    })
    if (stat) {
      return '-'
    } else {
      return codes[type].filter(item => {
        return item['value'] === value
      })[0]['children'].filter(item => {
        return item['value'] === childValue
      })[0]['text']
    }
  },
  // 后台返回的数组，数组元素是码值
  getArrayTextByCode(type, value) {
    if (!value) return '-'
    let name = ''
    value.forEach((arrVal, index) => {
      const stat = codes[type].every(item => {
        return item['value'] !== arrVal
      })
      if (stat) {
        name += ''
      } else {
        name += codes[type].filter(item => {
          return item['value'] === arrVal
        })[0]['text'] + ','
      }
    })
    return name.slice(0, -1)
  },
  // 后台返回的数组，数组元素是码值
  getArrayTextByCode1(type, value) {
    if (!value) return '-'
    let name = ''
    value.forEach((arrVal, index) => {
      const stat = codes[type].every(item => {
        return item['value'] !== arrVal
      })
      if (stat) {
        name += ''
      } else {
        name += codes[type].filter(item => {
          return item['value'] === arrVal
        })[0]['text'] + ','
      }
    })
    return name.slice(0, -1)
  }
}

