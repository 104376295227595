// 导入组件
import Vue from 'vue'
import Router from 'vue-router'
// 登录
import login from '@/views/login'
// 首页
import index from '@/views/index'
/**
 * 基础菜单
 */
// 商品管理
import Goods from '@/views/goods/Goods'
/**
 * 系统管理
 */
// 用户管理
import user from '@/views/system/user'
// 菜单管理
// 角色管理
import Role from '@/views/system/Role'
// 组织管理
import organizationalManagement from '@/views/system/organizationalManagement'
// 公司管理
// 系统环境变量
// 权限管理
import Permission from '@/views/system/Permission'
// 监控查询

// 图表界面
import basicSettingRoute from './basicSetting'
import hangOutManagementRoute from './hangOutManagement'
import accountManagementSystem from './accountManagementSystem'
import generationMiningRoute from './generationMining'
import tradeRoute from './trade'
import warehousingSystemRouter from './warehousingSystem'
import orderRouter from './transactionData'
import orderFinancingRoute from './orderFinancing'
import biDataCenterRoute from './biDataCenter'
import shoppingMallRoute from './shoppingMall'
import tradeFinancingRoute from './tradeFinancing'
import supplyChainRoute from './supplyChain'
import piaojuRoute from './piaoju'
import storageProcessRoute from './storageProcess'
// 启用路由
Vue.use(Router)

// 导出路由
const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: login,
      hidden: true,
      meta: {
        name: '登录',
        requireAuth: false
      }
    },
    {
      path: '/homePage/index',
      name: 'homePage',
      component: () => import('@/views/homePage/index.vue'),
      meta: {
        name: '首页',
        parentPath: '/homePage/index',
        requireAuth: true
      }

    },
    {
      path: '/projectAddress/index',
      name: 'projectAddress',
      component: () => import('../views/projectAddress/index.vue'),
      meta: {
        name: '页面地址',
        parentPath: '/projectAddress/index',
        requireAuth: false
      }
    },
    {
      path: '/',
      component: index,
      iconCls: 'el-icon-tickets',
      redirect: '/goods/Goods',
      children: [
        ...orderFinancingRoute,
        ...orderRouter,
        ...tradeRoute,
        ...warehousingSystemRouter,
        ...basicSettingRoute,
        ...hangOutManagementRoute,
        ...accountManagementSystem,
        ...generationMiningRoute,
        ...biDataCenterRoute,
        ...shoppingMallRoute,
        ...tradeFinancingRoute,
        ...supplyChainRoute,
        ...piaojuRoute,
        ...storageProcessRoute,
        {
          path: '/goods/Goods',
          name: 'goods',
          component: Goods,
          meta: {
            name: '材质管理',
            parentPath: '/goods/Goods',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/goods/placeOrigin',
          name: 'placeOrigin',
          component: () => import('../views/goods/placeOrigin.vue'),
          meta: {
            name: '产地管理',
            parentPath: '/goods/placeOrigin',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/goods/varietiesMapping',
          name: 'varietiesMapping',
          component: () => import('../views/goods/varietiesMapping.vue'),
          meta: {
            name: '品种映射',
            parentPath: '/goods/varietiesMapping',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/goods/specificationManagement',
          name: 'specificationManagement',
          component: () => import('../views/goods/specificationManagement.vue'),
          meta: {
            name: '规格管理',
            parentPath: '/goods/specificationManagement',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/goods/heavyItems',
          name: 'heavyItems',
          component: () => import('../views/goods/heavyItems.vue'),
          meta: {
            name: '件重管理',
            parentPath: '/goods/heavyItems',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/goods/commodityManagement',
          name: 'commodityManagement',
          component: () => import('../views/goods/commodityManagement.vue'),
          meta: {
            name: '商品管理',
            parentPath: '/goods/commodityManagement',
            keepAlive: true,
            requireAuth: true
          }
        }, {
          path: '/goods/commodityManagementDeatail',
          name: 'commodityManagementDeatail',
          component: () => import('../views/goods/children/commodityManagementDeatail.vue'),
          meta: {
            name: '商品详情',
            parentPath: '/goods/commodityManagement',
            requireAuth: true
          }
        },
        {
          path: '/goods/index',
          name: 'goodsIndex',
          component: () => import('../views/goods/index.vue'),
          meta: {
            name: '品种管理',
            keepAlive: true,
            parentPath: '/goods/index',
            requireAuth: true
          }
        },
        {
          path: '/system/user',
          name: 'systemUser',
          component: user,
          meta: {
            name: '成员管理',
            keepAlive: true,
            parentPath: '/system/user',
            requireAuth: true
          }
        },
        {
          path: '/system/Role',
          name: 'Role',
          component: Role,
          meta: {
            name: '角色管理',
            keepAlive: true,
            parentPath: '/system/Role',
            requireAuth: true
          }
        },
        {
          path: '/system/organizationalManagement',
          name: 'organizationalManagement',
          component: organizationalManagement,
          meta: {
            name: '部门管理',
            keepAlive: true,
            parentPath: '/system/organizationalManagement',
            requireAuth: true
          }
        },
        {
          path: '/system/Permission',
          name: 'Permission',
          component: Permission,
          meta: {
            name: '权限管理',
            keepAlive: true,
            parentPath: '/system/Permission',
            requireAuth: true
          }
        },
        {
          path: '/system/systemLog',
          name: 'systemLog',
          component: () => import('../views/system/systemLog.vue'),
          meta: {
            name: '系统日志',
            keepAlive: true,
            parentPath: '/system/systemLog',
            requireAuth: true
          }
        },
        {
          path: '/websiteManagement/newsList',
          name: 'newsList',
          component: () => import('../views/websiteManagement/index.vue'),
          meta: {
            name: '新闻列表',
            keepAlive: true,
            parentPath: '/websiteManagement/newsList',
            requireAuth: true
          }
        },
        {
          path: '/websiteManagement/addAndEditNews',
          name: 'addAndEditNews',
          component: () =>
            import('../views/websiteManagement/addAndEditNews.vue'),
          meta: {
            name: '添加修改新闻',
            parentPath: '/websiteManagement/newsList',
            requireAuth: true
          }
        },
        {
          path: '/websiteManagement/messageManagement',
          name: 'messageManagement',
          component: () =>
            import('../views/websiteManagement/messageManagement.vue'),
          meta: {
            name: '留言管理',
            keepAlive: true,
            parentPath: '/websiteManagement/messageManagement',
            requireAuth: true
          }
        },
        {
          path: '/system/dictionaryType',
          name: 'dictionaryType',
          component: () => import('../views/system/dictionaryType.vue'),
          meta: {
            name: '字典类型',
            keepAlive: true,
            parentPath: '/system/dictionaryType',
            requireAuth: true
          }
        },
        {
          path: '/system/dictionaryData',
          name: 'dictionaryData',
          component: () => import('../views/system/dictionaryData.vue'),
          meta: {
            name: '字典数据',
            keepAlive: true,
            parentPath: '/system/dictionaryData',
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/index',
          name: 'memberManagementIndex',
          component: () => import('../views/memberManagement/index.vue'),
          meta: {
            name: '会员中心',
            parentPath: '/memberManagement/index',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/businessManage',
          name: 'businessManage',
          component: () => import('../views/memberManagement/businessManage.vue'),
          meta: {
            name: '商圈配置管理',
            keepAlive: true,
            parentPath: '/memberManagement/businessManage',
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/businessManage/detail',
          name: 'businessManageDetail',
          component: () => import('../views/memberManagement/children/businessManageDetail.vue'),
          meta: {
            name: '商圈配置详情',
            parentPath: '/memberManagement/businessManage/detail',
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/bankAccessManage',
          name: 'bankAccessManage',
          component: () => import('../views/memberManagement/bankAccessManage.vue'),
          meta: {
            name: '银行准入管理',
            keepAlive: true,
            parentPath: '/memberManagement/bankAccessManage',
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/bankAccessManageDetail',
          name: 'bankAccessManageDetail',
          component: () => import('../views/memberManagement/children/bankAccessManageAudit.vue'),
          meta: {
            name: '银行准入详情',
            parentPath: '/memberManagement/bankAccessManage',
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/bankAccessManageAudit',
          name: 'bankAccessManageAudit',
          component: () => import('../views/memberManagement/children/bankAccessManageAudit.vue'),
          meta: {
            name: '银行准入审核',
            parentPath: '/memberManagement/bankAccessManageAudit',
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/contractTemplate',
          name: 'contractTemplate',
          component: () =>
            import('../views/memberManagement/contractTemplate.vue'),
          meta: {
            name: '合同模板管理',
            keepAlive: true,
            parentPath: '/memberManagement/contractTemplate',
            requireAuth: true
          }
        },

        {
          path: '/memberManagement/lookedUpManagement',
          name: 'lookedUpManagement',
          component: () =>
            import('../views/memberManagement/lookedUpManagement.vue'),
          meta: {
            name: '抬头管理',
            keepAlive: true,
            parentPath: '/memberManagement/lookedUpManagement',
            requireAuth: true
          }
        },

        {
          path: '/memberManagement/editMember',
          name: 'editMember',
          component: () =>
            import('../views/memberManagement/editMember.vue'),
          meta: {
            name: '会员编辑',
            parentPath: '/memberManagement/index',
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/addMember',
          name: 'addMember',
          component: () =>
            import('../views/memberManagement/addMember.vue'),
          meta: {
            name: '会员新增',
            parentPath: '/memberManagement/index',
            requireAuth: true,
            keepAlive: true
          }
        },
        {
          path: '/memberManagement/memberDetails',
          name: 'memberDetails',
          component: () =>
            import('../views/memberManagement/children/memberDetails.vue'),
          meta: {
            name: '会员信息',
            parentPath: '/memberManagement/index',
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/upstreamFirm',
          name: 'upstreamFirm',
          component: () =>
            import('../views/memberManagement/upstreamFirm.vue'),
          meta: {
            name: '合作企业',
            parentPath: '/memberManagement/upstreamFirm',
            keepAlive: true,
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/guaranteeCompany',
          name: 'guaranteeCompany',
          component: () =>
            import('../views/memberManagement/guaranteeCompany.vue'),
          meta: {
            name: '担保公司',
            keepAlive: true,
            parentPath: '/memberManagement/guaranteeCompany',
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/managementCompany',
          name: 'managementCompany',
          component: () =>
            import('../views/memberManagement/managementCompany.vue'),
          meta: {
            name: '资方公司',
            keepAlive: true,
            parentPath: '/memberManagement/managementCompany',
            requireAuth: true
          }
        },
        {
          path: '/memberManagement/relevanceEnterprises',
          name: 'relevanceEnterprises',
          component: () =>
            import('../views/memberManagement/relevanceEnterprises.vue'),
          meta: {
            name: '关联企业',
            parentPath: '/memberManagement/relevanceEnterprises',
            requireAuth: true
          }
        }
      ]
    }
  ]
})
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
