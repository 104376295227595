const accountManagementSystem = [
  {
    path: '/accountManagementSystem/accountInformation',
    name: 'accountInformation',
    component: () => import('../views/accountManagementSystem/accountInformation.vue'),
    meta: {
      name: '账户信息',
      parentPath: '/accountManagementSystem/accountInformation',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/accountManagementSystem/bankAccountManagement',
    name: 'bankAccountManagement',
    component: () => import('../views/accountManagementSystem/bankAccountManagement.vue'),
    meta: {
      name: '银行开户管理',
      parentPath: '/accountManagementSystem/bankAccountManagement',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/accountManagementSystem/bankAccountDetails',
    name: 'bankAccountDetails',
    component: () => import('../views/accountManagementSystem/children/bankAccountDetails.vue'),
    meta: {
      name: '银行开户详情',
      parentPath: '/accountManagementSystem/bankAccountManagement',
      requireAuth: true
    }
  },
  {
    path: '/accountManagementSystem/topUpManagement',
    name: 'topUpManagement',
    component: () => import('../views/accountManagementSystem/topUpManagement.vue'),
    meta: {
      name: '充值管理',
      parentPath: '/accountManagementSystem/topUpManagement',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/accountManagementSystem/withdrawDeposit',
    name: 'withdrawDeposit',
    component: () => import('../views/accountManagementSystem/withdrawDeposit.vue'),
    meta: {
      name: '提现管理',
      parentPath: '/accountManagementSystem/withdrawDeposit',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/accountManagementSystem/addTopUp',
    name: 'addTopUp',
    component: () => import('../views/accountManagementSystem/children/addTopUp.vue'),
    meta: {
      name: '充值新增',
      parentPath: '/accountManagementSystem/topUpManagement',
      requireAuth: true
    }
  },
  {
    path: '/accountManagementSystem/addWithdrawDeposit',
    name: 'addWithdrawDeposit',
    component: () => import('../views/accountManagementSystem/children/addWithdrawDeposit.vue'),
    meta: {
      name: '提现新增',
      parentPath: '/accountManagementSystem/accountInformation',
      requireAuth: true
    }
  },
  {
    path: '/accountManagementSystem/fundDetails',
    name: 'fundDetails',
    component: () => import('../views/accountManagementSystem/children/fundDetails.vue'),
    meta: {
      name: '提现新增',
      parentPath: '/accountManagementSystem/accountInformation',
      requireAuth: true
    }
  }
]
export default accountManagementSystem
