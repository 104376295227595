<template>
  <div class="slider" @mouseenter="changeSliderDisplay('block')" @mouseleave="changeSliderDisplay('hide')">
    <div class="content" :class="show ? 'content-block' : 'content-hide'">
      <div class="bg-img-div">
        <img id="bg-img" :src="data.backgroundImage" alt>
      </div>
      <div class="slider-img-div" :style="sliderImgStyles">
        <img id="slider-img" :src="data.sliderImage" alt>
      </div>
      <div class="refresh-btn" @click="$_refresh" />
    </div>
    <div class="slider-move">
      <div v-if="moveLeftWidth > 0 && !verificationSuccess" class="slider-fill" :style="{ width: moveLeftWidth + 'px', border: '1px solid' + borderColor, background: bgColor }" />
      <div class="slider-move-track" :class="{'verification-success' : verificationSuccess}">
        <i v-if="verificationSuccess" class="el-icon-check" /> {{ verificationSuccess ? '验证完成' : '向右拖动滑块填充拼图' }}
      </div>
      <div
        v-if="!verificationSuccess"
        class="slider-move-btn"
        :style="moveStyles"
        @mousedown="$_slider_mousedown($event)"
      />
    </div>
  </div>
</template>

<script>
import { generateCaptchaImage, validCaptchaImage } from '@/api/login'
import slideBtn from '@/assets/icon/slide_btn.png'
import slideIng from '@/assets/icon/slide_ing.png'
import slideSuccess from '@/assets/icon/slide_success.png'
import slideFail from '@/assets/icon/slide_fail.png'
export default {
  name: 'SliderVerify',
  props: {
    defaultShow: { type: Boolean, default: false },
    showClose: { type: Boolean, default: false },
    refreshSlide: { type: String, default: null }
  },
  data() {
    return {
      data: {
        backgroundImage: '',
        sliderImage: '',
        data: ''
      },
      start: 0,
      startY: 0,
      end: 316, // 滑动最大距离
      // 滑动轨迹滑动时间等数据
      trackData: {
        bgImageWidth: 0,
        bgImageHeight: 0,
        sliderImageWidth: 0,
        sliderImageHeight: 0,
        startSlidingTime: null,
        endSlidingTime: null,
        data: '',
        trackList: []
      },
      moveStyles: {
        transform: 'translate(0px, 0px)',
        background: `url(${slideBtn}) no-repeat left center`
      },
      sliderImgStyles: {
        transform: 'translate(0px, 0px)'
      },
      show: this.defaultShow,
      verificationSuccess: false, // 校验是否成功
      requestLock: null, // 图片信息是否请求成功
      moveLeftWidth: 0,
      moveStatus: 'fail',
      borderColor: '#0172ebff',
      bgColor: '#EAF4FC'
    }
  },
  watch: {
    refreshSlide: {
      handler(newVal) {
        if (newVal) {
          this.$_refresh()
        }
      }
    }
  },
  created() {
    this.$_gen()
  },
  methods: {
    /**
     * 生成滑动验证图片
     */
    async $_gen() {
      this.requestLock = false
      const res = await generateCaptchaImage({ key: this.data.data })
      this.data = res
      this.trackData.data = res.data
      this.verificationSuccess = false
      this.requestLock = true
    },
    /**
     * 验证
     */
    async $_check() {
      if (!this.trackData.trackList || this.trackData.trackList.length === 0) return
      const { code, msg } = await validCaptchaImage({ ...this.trackData })
      if (code === 200) {
        this.$emit('moveCheck', this.trackData)
        this.borderColor = '#27B01A'
        this.bgColor = '#EEF9EC'
        this.moveStyles.background = `url(${slideSuccess}) no-repeat top center`
        setTimeout(() => {
          this.show = false
          this.verificationSuccess = true
        }, 300)
        return
      }
      if (code === 400) this.$message.error(msg)
      this.$emit('moveCheck', {})
      this.borderColor = '#E95245'
      this.bgColor = '#FDF1F0'
      this.moveStyles.background = `url(${slideFail}) no-repeat top center`
      setTimeout(() => {
        this.$_refresh()
      }, 300)
    },
    /**
     * 刷新
     */
    $_refresh() {
      // 请求完成后再次才能执行
      if (this.requestLock) {
        this.$_reset()
        this.$_gen()
      }
    },
    /**
     * 重置
     */
    $_reset() {
      this.moveStyles.transform = 'translate(0, 0)'
      this.sliderImgStyles.transform = 'translate(0, 0)'
      this.start = 0
      this.startY = 0
      this.moveLeftWidth = 0
      this.trackData.startSlidingTime = null
      this.trackData.endSlidingTime = null
      this.trackData.trackList = []
      this.verificationSuccess = false
      this.borderColor = '#0172ebff'
      this.bgColor = '#EAF4FC'
      this.moveStyles.background = `url(${slideBtn}) no-repeat top center`
    },
    $_close() {
      this.show = false
    },

    /*
     * 滑动图片鼠标按下
     */
    $_slider_mousedown(event) {
      if (this.verificationSuccess) return // 校验成功时 不可以滑动
      this.trackData.startSlidingTime = new Date().getTime()
      this.start = event.pageX
      this.startY = event.pageY
      window.addEventListener('mousemove', this.$_move)
      window.addEventListener('mouseup', this.$_up)
    },
    /**
     * 移动
     * @param event
     */
    $_move(event) {
      if (window.TouchEvent && event.originalEvent instanceof TouchEvent) {
        event = event.touches[0]
      }
      let moveX = event.pageX - this.start
      let moveBtnX = event.pageX - this.start
      const pageX = event.pageX
      const pageY = event.pageY
      this.trackData.trackList.push({
        x: pageX - this.start,
        y: pageY - this.startY,
        t: (new Date().getTime() - this.trackData.startSlidingTime)
      })
      if (moveX < 0) {
        moveX = 0
        moveBtnX = 0
      } else if (moveX > this.end) {
        moveX = this.end
        moveBtnX = this.end + 16
      }
      this.moveStyles.transform = 'translate(' + moveBtnX + 'px, 0px)'
      this.sliderImgStyles.transform = 'translate(' + moveX + 'px, 0px)'
      this.moveLeftWidth = moveBtnX
      this.show = true
      this.moveStyles.background = `url(${slideIng}) no-repeat top center`
    },
    /**
     * 鼠标松下,进行验证
     * @param event
     */
    $_up(event) {
      this.trackData.endSlidingTime = new Date().getTime()
      window.removeEventListener('mousemove', this.$_move)
      window.removeEventListener('mouseup', this.$_up)
      this.$_check()
    },
    // 鼠标移入显示图片
    changeSliderDisplay(data) {
      if (this.verificationSuccess) return // 校验成功时 不需要再显示验证图片
      if (data === 'block') this.show = true
      if (data === 'hide') this.show = false
      if (this.show) {
        setTimeout(() => {
          this.getboxWidth()
        }, 200)
      }
    },
    // 获取图片和滑块宽高
    getboxWidth() {
      const bgElements = document.getElementsByClassName('bg-img-div')
      this.trackData.bgImageWidth = bgElements.item(0).clientWidth
      this.trackData.bgImageHeight = bgElements.item(0).clientHeight
      const sliderElements = document.getElementsByClassName('slider-img-div')
      this.trackData.sliderImageWidth = sliderElements.item(0).clientWidth
      this.trackData.sliderImageHeight = sliderElements.item(0).clientHeight
    }
  }
}
</script>

<style scoped>
.slider {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: relative;
  margin-bottom: 20px;
}

.slider .content {
  width: 100%;
  height: 236px;
  position: absolute;
  bottom:70px;
  transition: .2s;
}
@keyframes loading {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.content-block {
  display: block;
  animation: loading 0.5s;
}
.content-hide {
  display: none;
}

.bg-img-div,
.slider-img-div {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translate(0px, 0px);
}

.bg-img-div img {
  width: 100%;
}

.slider-img-div img {
  height: 100%;
}

.slider .slider-move {
  width: 100%;
  height: 61px;
  padding-top: 20px;
  position: relative;
}

.slider-move .slider-fill {
  height: 39px;
  position: absolute;
  left: 0;
}

.slider-move .slider-move-track {
  line-height: 41px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  color: #88949d;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: #F3F3F3;
}

.slider-move .slider-move-track.verification-success {
  color: #27B01A;
  font-weight: 700;
  background-color: #EEF9EC;
  border: 1px solid #27B01A;
}

.slider {
  user-select: none;
}

.slider-move .slider-move-btn {
  position: absolute;
  top: 20px;
  left: 0;
  width: 56px;
  height: 41px;
  background-size: 100% 100% !important;
}

.slider-move-btn:hover, .refresh-btn:hover {
  cursor: pointer
}

.refresh-btn {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  background: url('../assets/icon/refresh_icon.png') no-repeat top center;
  background-size: 100% 100%;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 9999;
}
</style>
